import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const url = `${apiEndpoint}/forecast`;

export async function forecastMetric(metricId, start, end) {
  let fullUrl = `${url}?metricId=${metricId}`;

  if (start && end) {
    fullUrl += `&start=${start}&end=${end}`;
  }
  try {
    const response = await HttpService.get(fullUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
