import React, { useState, useEffect, useMemo } from "react";
import AppRouter from "./AppRouter";
import {
  createMuiTheme,
  MuiThemeProvider,
  CircularProgress,
  Typography
} from "@material-ui/core";
import {
  primaryDark,
  mainYellow,
  mainBlue,
  mainBlueLight,
  primaryDarkLight
} from "./styles/colors";
import {
  UserContext,
  ProjectContext,
  DeviceContext,
  CounterContext,
  MetricsContext,
  ActionsContext,
  QueryContext,
  ThemeContext
} from "./contextStore";
import { getSession } from "./services/CognitoService";
import { getCustomer } from "./services/CustomerService";
import PageWrapper from "./components/PageWrapper";
import { getProjects } from "./services/ProjectService";
import { getDevices } from "./services/DeviceService";
import { getCounters } from "./services/CounterService";
import { getMetrics } from "./services/MetricService";
import { getActions } from "./services/ActionService";
import LinearBuffer from "./components/LinearBuffer";
import { APP_VERSION } from "./config";
import HashpleLoader from "./components/HashpleLoader";

function readCookie(a) {
  var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

function getInitialTheme() {
  const cookieTheme = readCookie("hashple-theme");
  if (cookieTheme) return cookieTheme;
  return "dark";
}

function App() {
  const [user, setUser] = useState(false);
  const [projects, setProjects] = useState([]);
  const [devices, setDevices] = useState([]);
  const [currentProject, setCurrentProject] = useState(false);
  const [counters, setCounters] = useState([]);
  const [actions, setActions] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showQuery, setShowQuery] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(getInitialTheme());

  useEffect(() => {
    if (currentTheme === "dark") {
      document.body.style.background = "rgb(26, 26, 26)";
    } else {
      document.body.style.background = "#edf1f7";
    }
  }, [currentTheme]);

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: currentTheme === "dark" ? primaryDark : mainBlueLight
          },
          secondary: {
            main: currentTheme === "dark" ? mainBlue : mainBlueLight
          },
          type: currentTheme
        },
        overrides: {
          MuiDrawer: {
            paper: {
              backgroundColor:
                currentTheme === "dark" ? primaryDarkLight : "white"
            }
          },
          MuiPaper: {
            root: {
              transition: "background-color linear 200ms",
              backgroundColor: currentTheme === "dark" ? primaryDark : "#ffffff"
            },
            elevation4: {
              boxShadow:
                "0px 1px 2px -1px rgba(0,0,0,0.2),0px -1px 5px 0px rgba(0,0,0,0.14),0px 1px 4px 0px rgba(0,0,0,0.12)"
            }
          },
          MuiFormLabel: {
            root: {
              "&$focused": {
                color: "lightgray"
              }
            }
          },
          MuiTypography: {
            root: {
              color: currentTheme === "dark" ? "white" : "black"
            }
          },
          MuiButton: {
            root: {
              textTransform: "none",
              color: "black"
            },
            contained: {
              boxShadow: 0
            }
          },
          MuiTableCell: {
            head: {
              fontWeight: "bold",
              borderBottom: `2px solid ${mainYellow}`
            },
            body: {
              borderBottom: "0px solid white"
            }
          }
        }
      }),
    [currentTheme]
  );

  async function checkForUser() {
    try {
      const session = await getSession();
      if (!session) {
        window.location = "https://www.hashple.io";
        return;
      }
      const userResp = await getCustomer();
      const promises = [
        getProjects(),
        getDevices(),
        getCounters(),
        getMetrics(),
        getActions()
      ];
      const [
        projectsResp,
        devicesResp,
        countersResponse,
        metricsResponse,
        actionsResponse
      ] = await Promise.all(promises);
      setProjects(projectsResp);
      setCurrentProject(projectsResp[0]);
      setDevices(devicesResp);
      setCounters(countersResponse);
      setActions(actionsResponse);
      const sortedMetrics = metricsResponse.sort(
        (a, b) => Boolean(b.total) - Boolean(a.total)
      );
      setMetrics(sortedMetrics);
      setUser(userResp);
      setTimeout(() => {
        setLoading(false);
      }, 400);
    } catch (e) {
      console.log("e", e);
      setLoading(false);
    }
  }

  function updateProjects(newProject) {
    const copy = projects.slice(0);

    const idx = copy.findIndex(item => item.projectId === newProject.projectId);

    if (idx > -1) {
      copy.splice(idx, 1, newProject);
    } else {
      copy.push(newProject);
    }

    setProjects(copy);
  }

  function updateDevices(newDevice, remove) {
    const copy = devices.slice(0);

    const idx = copy.findIndex(item => item.deviceId === newDevice.deviceId);

    if (remove) {
      copy.splice(idx, 1);
    } else if (idx > -1) {
      copy.splice(idx, 1, newDevice);
    } else {
      copy.push(newDevice);
    }
    setDevices(copy);
  }

  function updateCounters(newCounter, remove) {
    let copy = counters.slice(0);
    const idx = copy.findIndex(item => item.counterId === newCounter.counterId);

    if (remove) {
      copy.splice(idx, 1);
    } else if (idx > -1) {
      copy.splice(idx, 1, newCounter);
    } else if (Array.isArray(newCounter)) {
      copy = [...copy, ...newCounter];
    } else {
      copy.push(newCounter);
    }

    setCounters(copy);
  }

  async function refreshDevices() {
    try {
      const newDevices = await getDevices();
      setDevices(newDevices);
    } catch (e) {
      console.log("e: ", e);
      alert("Error refreshing actions");
    }
  }

  async function refreshMetrics() {
    try {
      const newMetrics = await getMetrics();
      setMetrics(newMetrics);
    } catch (e) {
      alert("Error refreshing actions");
    }
  }

  async function refreshCounters() {
    try {
      const newCounters = await getCounters();
      setCounters(newCounters);
    } catch (e) {
      alert("Error refreshing actions");
    }
  }

  async function refreshActions() {
    setShowQuery(true);
    try {
      const newActions = await getActions();
      setActions(newActions);
      setShowQuery(false);
    } catch (e) {
      alert("Error refreshing actions");
      setShowQuery(false);
    }
  }

  function updateMetrics(newMetric) {
    const copy = metrics.slice(0);
    copy.push(newMetric);
    setMetrics(copy);
  }
  function updateActions(newAction, remove) {
    const copy = actions.slice(0);
    const indexOf = actions.findIndex(
      action => action.actionId === newAction.actionId
    );

    if (remove) {
      copy.splice(indexOf, 1);
    } else if (indexOf > -1) {
      copy.splice(indexOf, 1, newAction);
    } else {
      copy.push(newAction);
    }
    setActions(copy);
  }

  useEffect(() => {
    checkForUser();
  }, []);

  let userValue = user;

  if (userValue) {
    userValue = {
      ...user,
      updateUser: newUser => setUser(newUser)
    };
  }

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <QueryContext.Provider
          value={{
            show: () => setShowQuery(true),
            hide: () => setShowQuery(false),
            active: showQuery
          }}
        >
          <ThemeContext.Provider
            value={{
              theme: currentTheme,
              changeTheme: () => {
                document.cookie = `hashple-theme=${
                  currentTheme === "dark" ? "light" : "dark"
                }`;
                setCurrentTheme(state => (state === "dark" ? "light" : "dark"));
              }
            }}
          >
            <ActionsContext.Provider
              value={{ actions, updateActions, refreshActions }}
            >
              <MetricsContext.Provider
                value={{ metrics, updateMetrics, refreshMetrics }}
              >
                <CounterContext.Provider
                  value={{ counters, updateCounters, refreshCounters }}
                >
                  <DeviceContext.Provider
                    value={{ devices, updateDevices, refreshDevices }}
                  >
                    <ProjectContext.Provider
                      value={{
                        projects,
                        currentProject,
                        updateProjects,
                        setCurrentProject
                      }}
                    >
                      <UserContext.Provider value={userValue}>
                        {loading ? (
                          <PageWrapper verticalCenter horizontalCenter>
                            {/*<CircularProgress size={120} color="secondary" /> */}
                            <HashpleLoader />
                            {/* <img src={require("./assets/logo.png")} height={200} /> */}
                          </PageWrapper>
                        ) : (
                          <React.Fragment>
                            {showQuery && <LinearBuffer />}
                            <AppRouter />
                          </React.Fragment>
                        )}
                      </UserContext.Provider>
                    </ProjectContext.Provider>
                  </DeviceContext.Provider>
                </CounterContext.Provider>
              </MetricsContext.Provider>
            </ActionsContext.Provider>
          </ThemeContext.Provider>
        </QueryContext.Provider>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default App;
