import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useFormInput } from "../../utils/hooks";
import moment from "moment-timezone";

function DateRangeSelectorDialog({ onClose, onSubmit }) {
  const from = useFormInput(moment().format("YYYY-MM-DD"));
  const to = useFormInput(moment().format("YYYY-MM-DD"));

  return (
    <Dialog fullWidth open>
      <DialogTitle>Pick Date Range</DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          style={{ marginBottom: 20 }}
          {...from}
          variant="filled"
          fullWidth
          type="date"
          label="From"
        />
        <TextField
          style={{ marginBottom: 20 }}
          fullWidth
          {...to}
          variant="filled"
          type="date"
          label="To"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit(from.value, to.value);
          }}
          variant="contained"
          color="secondary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DateRangeSelectorDialog;
