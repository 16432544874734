import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  conatiner: {
    display: "flex",
    flexDirection: "column"
  },
  label: {
    marginBottom: 5,
    color: "gray"
  }
}));

function LabelWrapper({
  label,
  children,
  marginBottom,
  style = {},
  labelStyle = {}
}) {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      style={{ marginBottom: marginBottom || "inherit", ...style }}
    >
      <Typography style={labelStyle} className={classes.label}>
        {label}
      </Typography>
      {children}
    </div>
  );
}

export default LabelWrapper;
