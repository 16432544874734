import React from "react";
import {
  TextField,
  makeStyles,
  Button,
  Slide,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useFormInput } from "../../../../utils/hooks";
import { useState } from "react";
import { createProject } from "../../../../services/ProjectService";
import { useContext } from "react";
import { ProjectContext, ViewContext } from "../../../../contextStore";
import { VIEWS } from "../../../../AppRouter";

const useStyles = makeStyles(() => ({
  input: {
    marginTop: 50,
  },
  continue: {
    alignSelf: "flex-end",
    marginTop: 20,
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: " center",
  },
  spinner: {
    marginTop: 40,
    marginBottom: 20,
  },
}));

function CreateNewProject() {
  const classes = useStyles();
  const projectName = useFormInput("");
  const [loading, setLoading] = useState(false);
  const { updateProjects } = useContext(ProjectContext);
  const { setCurrentView } = useContext(ViewContext);

  async function onContinueClick() {
    try {
      setLoading(true);
      const newProject = await createProject(projectName.value);
      updateProjects(newProject);
      setCurrentView(VIEWS.MAIN);
    } catch (e) {
      setLoading(false);
      alert("Error creating project");
    }
  }

  return (
    <Slide timeout={400} in direction="left">
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress
            className={classes.spinner}
            size={70}
            color="secondary"
          />
          <Typography>Creating Project...</Typography>
        </div>
      ) : (
        <div className={classes.container}>
          <TextField
            color="secondary"
            className={classes.input}
            fullWidth
            {...projectName}
            variant="filled"
            label="Project Name"
          />
          <Button
            className={classes.continue}
            variant="contained"
            color="secondary"
            onClick={onContinueClick}
            disabled={!projectName.value.length}
          >
            Continue
          </Button>
        </div>
      )}
    </Slide>
  );
}

export default CreateNewProject;
