import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const url = `${apiEndpoint}/generic`;

export async function createItem(item, table) {
  try {
    const response = await HttpService.post(url, { item, table });
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteItem(id, key, table) {
  try {
    await HttpService.delete(`${url}?id=${id}&key=${key}&table=${table}`);
    return true;
  } catch (e) {
    throw e;
  }
}

export async function patchItem(body, id, key, table) {
  try {
    await HttpService.patch(`${url}?id=${id}&key=${key}&table=${table}`, body);
    return true;
  } catch (e) {
    throw e;
  }
}
