import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Card,
  Button,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  useMediaQuery,
  ListItem,
  ListItemText,
  List,
  IconButton,
  Collapse,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Slide,
  Checkbox,
  TableSortLabel
} from "@material-ui/core";
import {
  ActionsContext,
  QueryContext,
  ThemeContext,
  UserContext
} from "../../../../contextStore";
import { Edit, Code, Delete, Add } from "@material-ui/icons";
import EditAction from "../EditAction";
import { createAction, deleteAction } from "../../../../services/ActionService";
import AceEditor from "react-ace";
import { addCompleter } from "ace-builds/src-noconflict/ext-language_tools";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-twilight";
import TableTitleChip from "../../../../components/TableTitleChip";
import { mainYellow } from "../../../../styles/colors";
import { useFormInput, useDialog } from "../../../../utils/hooks";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import HashpleEditor from "../../../../components/HashpleEditor";
import {
  isThereErrorInCode,
  getTableRowStyle,
  sortArray
} from "../../../../utils";
import { v1 } from "uuid";
import { patchItem } from "../../../../services/DbService";

const useStyles = makeStyles(() => ({
  container: {
    margin: 20
  },
  title: {
    marginBottom: 20,
    fontWeight: "bold"
  },
  trackOn: {
    background: mainYellow
  },
  trackOff: {
    background: "indianred"
  },
  switchOff: {
    background: "indianred"
  },
  addItem: {
    "&:hover": {
      background: "rgba(255,255,255,.1)"
    }
  }
}));

function ActionList() {
  const classes = useStyles();
  const { actions, updateActions, refreshActions } = useContext(ActionsContext);
  const user = useContext(UserContext);
  const query = useContext(QueryContext);
  const currentTheme = useContext(ThemeContext);
  const minWidthMatches = useMediaQuery("(max-width:900px)");
  const [editAction, setEditAction] = useState(false);
  const [editActionName, setEditActionName] = useState(false);
  const addActionDialog = useDialog();
  const deleteDialog = useDialog();
  const newActionName = useFormInput("");
  const refreshInterval = useRef(false);
  const [sortBy, setSortBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [sorted, setSorted] = useState(sortArray(actions, order, sortBy));

  function flipSortBy(newSort) {
    const newOrder = order === "asc" ? "desc" : "asc";
    setSortBy(newSort);
    setOrder(newOrder);
    setSorted(sortArray(actions, newOrder, newSort));
  }

  useEffect(() => {
    setSorted(actions, order, sortBy);
  }, [actions]);

  useEffect(() => {
    refreshInterval.current = setInterval(() => {
      refreshActions();
    }, 10000);

    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
        refreshInterval.current = false;
      }
    };
  }, []);

  function updateAction(newAction) {
    updateActions(newAction);
    setEditAction(false);
    addActionDialog.close();
  }

  async function handleSwitchClick(action) {
    query.show();
    const newAction = {
      ...action,
      off: !action.off
    };

    try {
      await patchItem(
        { off: !action.off },
        action.actionId,
        "actionId",
        "action"
      );
      updateActions(newAction);
      query.hide();
    } catch (e) {
      console.log("Error updating action", e);
      alert("Error updating action: ");
    }
  }

  function handleSaveActionName(newAction) {
    updateActions(newAction);
    query.hide();
    setEditActionName(false);
  }

  async function handleDeleteAction() {
    query.show();
    try {
      await deleteAction(deleteDialog.open.actionId);
      updateActions(deleteDialog.open, true);
      deleteDialog.close();
      query.hide();
    } catch (e) {
      alert("Error deleting action");
      query.hide();
    }
  }

  return (
    <React.Fragment>
      {editActionName && (
        <EditActionName
          action={editActionName}
          handleClose={() => setEditActionName(false)}
          handleSave={handleSaveActionName}
        />
      )}
      {addActionDialog.open && (
        <AddActionDialog
          handleClose={() => addActionDialog.close()}
          handleSave={updateAction}
        />
      )}
      {deleteDialog.open && (
        <ConfirmationDialog
          handleSubmit={handleDeleteAction}
          handleClose={() => deleteDialog.close()}
          title="Delete Action"
          content="Are you sure you would like to delete this action?"
          submitText="Delete"
        />
      )}

      <Slide in direction="right">
        <div className={classes.container}>
          <Card>
            <CardContent>
              {!minWidthMatches && (
                <React.Fragment>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            active={sortBy === "name"}
                            direction={order}
                            onClick={() => flipSortBy("name")}
                          >
                            Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={sortBy === "lastExecuted"}
                            direction={order}
                            onClick={() => flipSortBy("lastExecuted")}
                          >
                            Last Executed
                          </TableSortLabel>
                        </TableCell>
                        <TableCell padding="checkbox">Actions</TableCell>
                        <TableCell padding="checkbox" />
                        <TableCell padding="checkbox" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {actions.map((action, actionIdx) => (
                        <React.Fragment>
                          <TableRow
                            style={{
                              backgroundColor:
                                actionIdx % 2
                                  ? "inherit"
                                  : currentTheme.theme === "light"
                                  ? "#f0f0f0"
                                  : "rgb(42, 42, 42)"
                            }}
                            key={action.actionId}
                          >
                            <TableCell>
                              {!editAction ||
                              (editAction &&
                                editAction.actionId !== action.actionId) ? (
                                action.name
                              ) : (
                                <TextField
                                  color="secondary"
                                  {...newActionName}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {action.lastExecuted
                                ? new Date(action.lastExecuted).toLocaleString()
                                : "-"}
                            </TableCell>
                            <TableCell padding="checkbox">
                              <Switch
                                size="small"
                                onClick={() => handleSwitchClick(action)}
                                classes={{
                                  track: action.off && classes.trackOff,
                                  thumb: action.off && classes.trackOff
                                }}
                                checked={!action.off}
                              />
                            </TableCell>
                            <TableCell padding="checkbox">
                              <IconButton
                                onClick={() => {
                                  newActionName.onChange({
                                    target: { value: action.name }
                                  });
                                  setEditAction(action);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </TableCell>
                            <TableCell padding="checkbox">
                              <IconButton
                                onClick={() => deleteDialog.show(action)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            style={getTableRowStyle(
                              actionIdx,
                              currentTheme.theme
                            )}
                          >
                            {editAction &&
                              action.actionId === editAction.actionId && (
                                <TableCell colSpan={6}>
                                  <EditAction
                                    actionName={newActionName.value}
                                    action={editAction}
                                    updateAction={updateAction}
                                    handleClose={() => setEditAction(false)}
                                  />
                                </TableCell>
                              )}
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                  <div
                    onClick={() => addActionDialog.show()}
                    style={{
                      width: "100%",
                      height: 50,
                      border: "1px dashed gray",
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row"
                    }}
                    className={classes.addItem}
                  >
                    <Add style={{ marginRight: 10 }} />
                    <Typography>Add Action</Typography>
                  </div>
                </React.Fragment>
              )}
              {minWidthMatches && (
                <List>
                  {actions.map(action => (
                    <React.Fragment>
                      <ListItem>
                        <ListItemText
                          style={{ flexGrow: 0, marginRight: 20 }}
                          primary="Active:"
                        />
                        <Switch
                          size="small"
                          onClick={() => handleSwitchClick(action)}
                          classes={{
                            track: action.off && classes.trackOff,
                            thumb: action.off && classes.trackOff
                          }}
                          checked={!action.off}
                        />
                      </ListItem>
                      <ListItem key={action.actionId}>
                        <ListItemText primary={`Name: ${action.name}`} />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText
                          primary={`Last Executed: ${
                            action.lastExecuted
                              ? new Date(action.lastExecuted).toLocaleString()
                              : "-"
                          }`}
                        />
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
              )}
            </CardContent>
          </Card>
        </div>
      </Slide>
    </React.Fragment>
  );
}

function EditActionName({ action, handleClose, handleSave }) {
  const name = useFormInput(action.name);
  const query = useContext(QueryContext);

  async function saveName() {
    query.show();
    const newAction = {
      ...action,
      name: name.value
    };

    try {
      const actionResponse = await createAction(newAction);
      handleSave(actionResponse);
    } catch (e) {
      console.log("Error updating action", e);
      alert("Error updating action: ");
      query.hide();
    }
  }
  return (
    <Dialog open fullWidth>
      <DialogTitle>Edit Action Name</DialogTitle>
      <DialogContent>
        <TextField
          variant="filled"
          label="Action Name"
          {...name}
          fullWidth
          color="secondary"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => saveName()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AddActionDialog({ handleClose, handleSave }) {
  const name = useFormInput("");
  const query = useContext(QueryContext);
  const user = useContext(UserContext);
  const [ifAction, setIfAction] = useState("");
  const [thenAction, setThenAction] = useState("");
  const [elseAction, setElseAction] = useState("");
  const [useElse, setUseElse] = useState(false);

  async function handleCreateAction() {
    query.show();

    try {
      isThereErrorInCode(ifAction);
      isThereErrorInCode(thenAction);
      if (elseAction) {
        isThereErrorInCode(elseAction);
      }

      const newAction = {
        customerId: user.customerId,
        actionId: v1(),
        name: name.value,
        off: true,
        ifAction: ifAction.split("\n").join(";"),
        thenAction: thenAction.split("\n").join(";"),
        elseAction: !useElse
          ? null
          : elseAction.length
          ? elseAction.split("\n").join(";")
          : null
      };

      const actionResponse = await createAction(newAction);
      query.hide();
      handleSave(actionResponse);
    } catch (e) {
      console.log(e.message);
      alert(`There was an error updating action:\n ${e.message}`);
      query.hide();
    }
  }

  return (
    <Dialog open fullWidth>
      <DialogTitle>Create New Action</DialogTitle>
      <DialogContent>
        <TextField
          variant="filled"
          label="Action Name"
          {...name}
          fullWidth
          color="secondary"
        />
        <HashpleEditor
          action={ifAction}
          handleActionChange={newAction => setIfAction(newAction)}
          actionType="IF"
        />
        <HashpleEditor
          action={thenAction}
          handleActionChange={newAction => setThenAction(newAction)}
          actionType="THEN"
        />
        <div
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Checkbox
            style={{ paddingLeft: 0 }}
            checked={useElse}
            onClick={() => setUseElse(state => !state)}
            color="secondary"
          />
          <Typography>Use else statement</Typography>
        </div>
        {useElse && (
          <HashpleEditor
            action={elseAction}
            handleActionChange={newAction => setElseAction(newAction)}
            actionType="ELSE"
            placeholder="An else statement is optional"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleCreateAction}
          color="secondary"
          disabled={
            !ifAction.length || !thenAction.length || !name.value.length
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActionList;
