import React from "react";
import { Typography, makeStyles, Slide } from "@material-ui/core";
import { AddCircle, NoteAddOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed lightgray",
    height: 250,
    backgroundColor: "rgba(255,255,255,0.1)",
    marginTop: 50,
    borderRadius: 2,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.05)",
      border: `1px dashed ${theme.palette.secondary.main}`,
    },
  },
  addCirclOutlined: {
    color: "white",
    marginBottom: 20,
    fontSize: 36,
    fontWeight: "lighter",
  },
  text: {
    textAlign: "center",
    color: "ligthgray",
  },
}));

function EmptyProject({ handleNewProjectClick }) {
  const classes = useStyles();
  return (
    <Slide timeout={400} in direction="left">
      <div onClick={handleNewProjectClick} className={classes.container}>
        <NoteAddOutlined className={classes.addCirclOutlined} />
        <Typography className={classes.text}>Create Project</Typography>
      </div>
    </Slide>
  );
}

export default EmptyProject;
