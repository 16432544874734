import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { Typography, Divider, makeStyles } from "@material-ui/core";
import { useState } from "react";
import NoProjectsView from "./components/NoProjectsView";
import { MOCK_PROJECTS } from "../../mock/projects.mock";
import ProjectSelector from "./components/ProjectSelector";
import { useEffect } from "react";
import Dashboard from "./components/Dashboard";
import { useContext } from "react";
import {
  ProjectContext,
  DeviceContext,
  CounterContext,
  MetricsContext,
} from "../../contextStore";
import { VIEWS } from "../../AppRouter";
import DeviceCreation from "../../components/DeviceCreation";

const useStyles = makeStyles(() => ({
  divider: {
    background: "gray",
    margin: "5px 5px",
    width: "100%",
  },
}));

function HomePage() {
  const classes = useStyles();
  const { projects, currentProject } = useContext(ProjectContext);
  const { devices } = useContext(DeviceContext);
  const { counters } = useContext(CounterContext);
  const { metrics } = useContext(MetricsContext);

  function getTutorialState() {
    if (!projects || !projects.length) {
      return VIEWS.CREATE_PROJECT;
    }
    if (
      !devices ||
      !devices.length ||
      !counters ||
      !counters.length ||
      !metrics ||
      !metrics.length
    ) {
      return VIEWS.CREATE_DEVICE;
    }

    return false;
  }

  const tutorialState = getTutorialState();

  return (
    <PageWrapper fullHeight horizontalCenter>
      {tutorialState === VIEWS.CREATE_PROJECT && <NoProjectsView />}
      {tutorialState === VIEWS.CREATE_DEVICE && <DeviceCreation />}
      {!tutorialState && (
        <React.Fragment>
          <Dashboard currentProject={currentProject} />
        </React.Fragment>
      )}
    </PageWrapper>
  );
}

export default HomePage;
