import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import LabelWrapper from "../../components/LabelWrapper";
import moment from "moment-timezone";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import {
  Typography,
  makeStyles,
  TextField,
  NativeSelect,
  FilledInput,
  Button,
  Select,
  MenuItem
} from "@material-ui/core";
import { useFormInput } from "../../utils/hooks";
import {
  MetricsContext,
  QueryContext,
  UserContext,
  ProjectContext
} from "../../contextStore";
import { getMetricsValues } from "../../services/MetricValueService";
import PdfReport from "../../components/PdfReport";

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 20,
    textAlign: "center"
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  input: {
    margin: "0px 20px"
  },
  aggregationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20
  }
}));

function ReportPage() {
  const classes = useStyles();
  const { metrics } = useContext(MetricsContext);
  const { currentProject } = useContext(ProjectContext);
  const user = useContext(UserContext);

  const from = useFormInput(moment().format("YYYY-MM-DD"));
  const to = useFormInput(moment().format("YYYY-MM-DD"));
  const aggregationLevel = useFormInput("hour");
  const aggregationOperation = useFormInput("average");
  const format = useFormInput("pdf");
  const reportTitle = useFormInput(
    `${currentProject.name} report - ${moment().format("YYYY-MM-DD HH:mm:ss")}`
  );

  const metricsToDisplay = useFormInput([metrics[0].metricId]);
  const query = useContext(QueryContext);
  const [showPdfReport, setShowPdfReport] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [timeSlotData, setTimeSlotData] = useState([]);

  function isMinuteAllowed() {
    if (format.value === "pdf") return false;
    const toMoment = moment(to.value);
    const fromMoment = moment(from.value);
    const diff = toMoment.diff(fromMoment, "days");
    if (diff > 1) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (!isMinuteAllowed()) {
      aggregationLevel.onChange({ target: { value: "hour" } });
    }
  }, [to.value, from.value, format.value]);

  function createTimeSlots() {
    const result = [];
    let startDate = moment(from.value).startOf("day");
    let hitTarget = false;

    const end = moment(to.value)
      .endOf("day")
      .startOf("hour")
      .format("x");

    while (!hitTarget) {
      if (startDate.format("x") === end) {
        result.push({
          label: moment(startDate).toString(),
          points: [
            Number(startDate.format("x")),
            Number(
              moment(startDate)
                .endOf(aggregationLevel.value)
                .format("x")
            )
          ]
        });
        hitTarget = true;
        break;
      }

      result.push({
        label: moment(startDate).toString(),
        points: [
          Number(startDate.format("x")),
          Number(
            moment(startDate)
              .endOf(aggregationLevel.value)
              .format("x")
          )
        ]
      });
      startDate.add(aggregationLevel.value, 1);
    }

    return result;
  }

  function createCsv(data, timeSlots) {
    let result = `Date,`;

    for (let i = 0; i < metricsToDisplay.value.length; i++) {
      const metricId = metricsToDisplay.value[i];
      const metricFound = metrics.find(item => item.metricId === metricId);
      result += `${metricFound.metricName} (${aggregationOperation.value})${
        metricsToDisplay.value.length - 1 === i ? "" : ","
      }`;
    }

    result += "\n";
    for (let i = 0; i < timeSlots.length; i++) {
      result += `${moment(timeSlots[i].label).toString()},`;
      for (let j = 0; j < data.length; j++) {
        const val = Number(data[j][timeSlots[i].label]);
        result += `${Number.isNaN(val) ? 0 : val}${
          j === data.length - 1 ? "" : ","
        }`;
      }
      result += "\n";
    }

    let csvContent = `data:text/csv;charset=utf-8,${result}`;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${reportTitle.value}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    query.hide();
  }

  function aggregateData(data) {
    const timeSlots = createTimeSlots();
    console.log("timeSlots: ", timeSlots);
    const results = [];
    for (let i = 0; i < data.length; i++) {
      const aggregated = {};

      for (let j = 0; j < data[i].length; j++) {
        const metricData = data[i][j];
        const indexOfTime = timeSlots.findIndex(
          (item, idx) =>
            metricData.timestamp >= item.points[0] &&
            metricData.timestamp <= item.points[1]
        );
        if (indexOfTime < 0) continue;
        if (aggregated[timeSlots[indexOfTime].label]) {
          aggregated[timeSlots[indexOfTime].label].push(metricData.value);
        } else {
          aggregated[timeSlots[indexOfTime].label] = [metricData.value];
        }
      }

      const finalResult = {};

      const keys = Object.keys(aggregated);
      for (let k = 0; k < keys.length; k++) {
        const values = aggregated[keys[k]];

        let fullAggregation = 0;

        const type = aggregationOperation.value;
        if (type === "max") {
          fullAggregation = Math.max(...values);
        } else if (type === "min") {
          fullAggregation = Math.min(...values);
        } else if (type === "sum") {
          fullAggregation = values.reduce(
            (accum, val) => Number(accum) + Number(val)
          );
        } else if (type === "average") {
          const sum = values.reduce(
            (accum, val) => Number(accum) + Number(val)
          );
          fullAggregation = Number(sum / values.length).toFixed(2);
        }

        finalResult[keys[k]] = fullAggregation;
      }
      results.push(finalResult);
    }

    return { results, timeSlots };
  }
  async function createReport() {
    query.show();
    try {
      const promises = [];

      for (let i = 0; i < metricsToDisplay.value.length; i++) {
        promises.push(
          getMetricsValues(
            metricsToDisplay.value[i],
            `${from.value}&${to.value}`
          )
        );
      }

      const values = await Promise.all(promises);
      console.log("value: ", values);
      const { results, timeSlots } = aggregateData(values);

      if (format.value === "csv") {
        createCsv(results, timeSlots);
      } else if (format.value === "pdf") {
        setTableData(results);
        setTimeSlotData(timeSlots);

        handleCreatePdf();
      }
    } catch (e) {
      console.log("e: ", e);
      alert("Error getting metric values");
    }
  }

  function handleCreatePdf() {
    setShowPdfReport(true);
    setTimeout(() => {
      savePdfReport();
    }, 2000);
  }

  function sleep(timeout) {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }

  async function savePdfReport() {
    query.show();
    const fileName = currentProject.name;

    const element = document.getElementById("pdf_report");
    const HTML_Width = element.offsetWidth;
    const HTML_Height = element.offsetHeight;
    const top_left_margin = 15;
    const PDF_Width = HTML_Width + top_left_margin * 2;
    const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    const canvas_image_width = HTML_Width;
    const canvas_image_height = HTML_Height;

    const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    try {
      const canvas = await html2canvas(element);
      await sleep(1000);

      const imgData = canvas.toDataURL("image/png", 0.2);
      const doc = new JsPDF("p", "pt", [PDF_Height, PDF_Width]);

      doc.addImage(
        imgData,
        "PNG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );

      for (var i = 1; i <= totalPDFPages; i++) {
        doc.addPage(PDF_Width, PDF_Height);
        doc.addImage(
          imgData,
          "PNG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }
      doc.save(fileName);
      query.hide();
      setShowPdfReport(false);
    } catch (e) {
      console.log("error: ", e);
      query.hide();
    }
  }

  return (
    <PageWrapper>
      <Typography className={classes.title} variant="h5">
        Create a report
      </Typography>
      {showPdfReport && (
        <PdfReport
          reportTitle={reportTitle.value}
          tableData={tableData}
          timeSlots={timeSlotData}
          metricsToDisplay={metricsToDisplay.value}
          from={from.value}
          operation={aggregationOperation.value}
          to={to.value}
        />
      )}
      <LabelWrapper
        style={{ marginLeft: 20, marginRight: 20 }}
        label="Title"
        labelStyle={{
          color: "white",
          marginBottom: 10
        }}
      >
        <TextField
          variant="filled"
          label="Report Title"
          fullWidth
          {...reportTitle}
        />
      </LabelWrapper>
      <LabelWrapper
        label="Select Date Range"
        style={{ marginTop: 20 }}
        labelStyle={{ color: "white", marginBottom: 10, marginLeft: 20 }}
      >
        <div className={classes.dateContainer}>
          <TextField
            className={classes.input}
            variant="filled"
            {...from}
            type="date"
            label="From"
            fullWidth
          />
          <TextField
            className={classes.input}
            variant="filled"
            {...to}
            label="To"
            type="date"
            fullWidth
          />
        </div>
      </LabelWrapper>
      <div className={classes.aggregationContainer}>
        <LabelWrapper
          labelStyle={{ color: "white", marginBottom: 10 }}
          style={{ width: "100%", margin: "0px 20px" }}
          label="Aggregate By"
        >
          <NativeSelect
            input={<FilledInput label="Aggregation Level" />}
            variant="filled"
            fullWidth
            {...aggregationLevel}
            label="Aggregation Level"
          >
            <option value="hour">Hour</option>
            {isMinuteAllowed() && <option option="minute">Minute</option>}
          </NativeSelect>
        </LabelWrapper>
        <LabelWrapper
          labelStyle={{ color: "white", marginBottom: 10 }}
          label="Operation"
          style={{ width: "100%", margin: "0px 20px" }}
        >
          <NativeSelect
            input={<FilledInput />}
            fullWidth
            variant="filled"
            {...aggregationOperation}
          >
            <option option="average">Average</option>
            <option value="min">Minimum</option>
            <option value="max">Maximum</option>
            <option option="sum">Sum</option>
          </NativeSelect>
        </LabelWrapper>
      </div>
      <LabelWrapper
        style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}
        label="Choose Metrics"
        labelStyle={{
          color: "white",
          marginBottom: 10
        }}
      >
        <Select fullWidth multiple variant="filled" {...metricsToDisplay}>
          {metrics.map(metric => (
            <MenuItem value={metric.metricId}>{metric.metricName}</MenuItem>
          ))}
        </Select>
      </LabelWrapper>
      <LabelWrapper
        style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}
        label="Format"
        labelStyle={{
          color: "white",
          marginBottom: 10
        }}
      >
        <NativeSelect
          input={<FilledInput />}
          fullWidth
          variant="filled"
          {...format}
        >
          <option value="csv">CSV</option>
          <option value="pdf">PDF</option>
        </NativeSelect>
      </LabelWrapper>
      <Button
        style={{
          marginTop: 20,
          marginBottom: 80,
          marginLeft: 20,
          marginRight: 20
        }}
        onClick={createReport}
        variant="contained"
        disabled={query.active}
        color="secondary"
      >
        Create & Download Report
      </Button>
    </PageWrapper>
  );
}

export default ReportPage;
