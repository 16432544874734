import React, { useContext, useState, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  ButtonGroup,
  Button
} from "@material-ui/core";
import { UserContext, QueryContext, ThemeContext } from "../../contextStore";
import moment from "moment-timezone";
import { AddCircle, WbSunny, WbCloudy } from "@material-ui/icons";
import { getSubAccounts } from "../../services/CustomerService";
import CreateSubAccountDialog from "../../components/CreateSubAccountDialog";

function ProfilePage() {
  const user = useContext(UserContext);
  const [subAccounts, setSubAccounts] = useState([]);
  const query = useContext(QueryContext);
  const [showCreateSub, setShowCreateSub] = useState(false);
  const { theme, changeTheme } = useContext(ThemeContext);

  async function loadData() {
    query.show();
    try {
      const sub = await getSubAccounts();
      setSubAccounts(sub);
      query.hide();
    } catch (e) {
      alert("error getting subaccounts");
      query.hide();
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  function onSubAccountCreation() {
    setShowCreateSub(false);
    loadData();
  }

  return (
    <React.Fragment>
      {showCreateSub && (
        <CreateSubAccountDialog
          onClose={() => setShowCreateSub(false)}
          onSubmit={onSubAccountCreation}
        />
      )}
      <Card
        style={{
          margin: 20
        }}
      >
        <CardContent>
          <Typography>Name: {user.name}</Typography>
          <Typography>
            Account Created: {moment(user.createDate).toLocaleString()}
          </Typography>
          <Typography>Timezone: {user.timezone}</Typography>
          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <Typography style={{ marginRight: 20 }}>Theme:</Typography>
            <ButtonGroup>
              <Button
                color="secondary"
                onClick={theme === "dark" && changeTheme}
                variant={theme === "light" && "contained"}
              >
                Light
              </Button>
              <Button
                color="secondary"
                onClick={theme === "light" && changeTheme}
                variant={theme === "dark" && "contained"}
              >
                Dark
              </Button>
            </ButtonGroup>
          </div>
          {!user.parent && (
            <React.Fragment>
              <Divider style={{ margin: "20px 0px" }} />
              <Typography>
                Sub Accounts{" "}
                <span>
                  <IconButton onClick={() => setShowCreateSub(true)}>
                    <AddCircle />
                  </IconButton>
                </span>
              </Typography>
              {subAccounts && !!subAccounts.length && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Create Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subAccounts &&
                      !!subAccounts.length &&
                      subAccounts.map(subAccount => (
                        <TableRow>
                          <TableCell>{subAccount.name}</TableCell>
                          <TableCell>
                            {moment(subAccount.createDate).toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
              {!subAccounts ||
                (!Boolean(subAccounts.length) && (
                  <Typography style={{ textAlign: "center", marginTop: 20 }}>
                    No sub accounts have been created
                  </Typography>
                ))}
            </React.Fragment>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default ProfilePage;
