import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useContext } from "react";
import { UserContext, ViewContext } from "../contextStore";
import AuthPage from "../pages/AuthPage";
import HomePage from "../pages/HomePage";
import SideBar from "../components/SideBar";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import CreateProjectView from "../components/CreateProjectView";
import ConfigurationPage from "../pages/ConfigurationPage";
import AlarmsPage from "../pages/AlarmsPage";
import ProfilePage from "../pages/ProfilePage";
import ReportPage from "../pages/ReportPage";

const useStyles = makeStyles(() => ({
  createProjectContainer: {
    display: "flex",
    flexDirection: "column"
  }
}));

export const VIEWS = {
  MAIN: "MAIN",
  CREATE_PROJECT: "CREATE_PROJECT",
  CREATE_DEVICE: "CREATE_DEVICE"
};

function AppRouter() {
  const user = useContext(UserContext);
  const [currentView, setCurrentView] = useState(VIEWS.MAIN);
  const classes = useStyles();

  return (
    <ViewContext.Provider value={{ currentView, setCurrentView }}>
      <Router>
        <Route path="/" component={NavBar} />

        {user && (
          <React.Fragment>
            {/* <Route path="/" component={SideBar} /> */}
            {currentView === VIEWS.MAIN && (
              <Route exact path="/" component={HomePage} />
            )}
            {currentView === VIEWS.CREATE_PROJECT && (
              <Route path="/" component={CreateProjectView} />
            )}
            <Route path="/settings" component={ConfigurationPage} />
            <Route exact path="/alarms" component={AlarmsPage} />
            <Route exact path="/reports" component={ReportPage} />
            <Route exact path="/profile" component={ProfilePage} />
          </React.Fragment>
        )}
        {!user && (
          <React.Fragment>
            <Route
              exact
              path="/"
              render={() => <AuthPage authType="Login" />}
            />
            <Route
              exact
              path="/login"
              render={() => <AuthPage authType="Login" />}
            />
            <Route
              exact
              path="/register"
              render={() => <AuthPage authType="Register" />}
            />
          </React.Fragment>
        )}
      </Router>
    </ViewContext.Provider>
  );
}

export default AppRouter;
