import React, { useState } from "react";
import PageWrapper from "../PageWrapper";
import { Typography } from "@material-ui/core";
import CreateFirstDevice from "./components/CreateFirstDevice";

// EXAMPLE GRAPH STRUCTURE
// {
//   nodes: [
//     {
//       id: "id1",
//       name: "Base Box",
//       val: 1,
//     },
//   ],
//   links: [
//     {
//       source: "id1",
//       target: "id2",
//     },
//   ],

const EMPTY_GRAPH = {
  nodes: [],
  links: [],
};
function DeviceCreation() {
  const [graphData, setGraphData] = useState(EMPTY_GRAPH);

  function handleNodeClick(e) {
    console.log("e: ", e);
  }

  return (
    <React.Fragment>
      {/* {!!graphData.nodes.length ? (
        <ForceGraph2D onNodeClick={handleNodeClick} graphData={graphData} />
      ) : ( */}
      <CreateFirstDevice />
      {/* )} */}
    </React.Fragment>
  );
}

export default DeviceCreation;
