import React, { useContext, useState, useEffect } from "react";
import {
  QueryContext,
  ThemeContext,
  UserContext,
  ProjectContext,
} from "../../contextStore";
import { forecastMetric } from "../../services/ForecastService";
import { Line } from "react-chartjs-2";
import { ArrowBack } from "@material-ui/icons";
import moment from "moment-timezone";
import ChartToolbar from "../../components/ChartToolbar";
import { Card, CardContent, IconButton, Typography } from "@material-ui/core";
import Loader from "../Loader";
import { useRef } from "react";
import "chartjs-plugin-zoom";
import "hammerjs";

const COLORS = ["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51"];
const LINE_NAMES = ["Trend", "Periodic", "Oddities", "Actual", "Predicted"];

function ForecastContainer({ metric, handleBack }) {
  const query = useContext(QueryContext);
  const { currentProject } = useContext(ProjectContext);
  const [charts, setCharts] = useState(false);
  const currentTheme = useContext(ThemeContext);
  const [tool, setTool] = useState("ZOOM");
  const chartRef = useRef(false);

  function createChart(values, index) {
    return {
      label: LINE_NAMES[index],
      fill: false,
      lineTension: 0.3,
      backgroundColor: COLORS[index],
      //backgroundColor: gradient,
      borderColor: COLORS[index],
      borderCapStyle: "butt",
      borderDash: [],
      borderWidth: 1.5,
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: COLORS[index],
      pointBackgroundColor: COLORS[index],
      pointBorderWidth: 0.5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: COLORS[index],
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: values,
    };
  }

  async function loadData() {
    const { metricId } = metric;

    query.show();
    try {
      const { businessHours } = currentProject;
      let forecastedData;

      if (businessHours) {
        const [start, end] = businessHours.split("-");
        forecastedData = await forecastMetric(metricId, start, end);
      } else {
        forecastedData = await forecastMetric(metricId);
      }

      let labels = {};

      for (let i = 0; i < forecastedData.length; i++) {
        const item = forecastedData[i];

        for (let j = 0; j < item.length; j++) {
          const { time, val } = item[j];
          const timestamp = Number(moment(time).format("x"));

          if (labels[timestamp]) {
            labels[timestamp][i] = val;
          } else {
            labels[timestamp] = Array(forecastedData.length).fill(0);
            labels[timestamp][i] = val;
          }
        }
      }

      const realLabels = Object.keys(labels).map((item) => Number(item));
      const chartData = [[], [], [], [], []];
      const initChart = Object.values(labels);

      for (let i = 0; i < initChart[0].length; i++) {
        for (let j = 0; j < initChart.length; j++) {
          chartData[i].push(initChart[j][i] || 0);
        }
      }

      const dataSets = [];

      for (let i = 0; i < chartData.length; i++) {
        dataSets.push(createChart(chartData[i], i));
      }

      setCharts({ labels: realLabels, datasets: dataSets });
      query.hide();
    } catch (e) {
      console.log("e: ", e);
      query.hide();
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 20,
          marginLeft: 20,
          alignSelf: "flex-start",
        }}
      >
        <IconButton onClick={handleBack}>
          <ArrowBack />
        </IconButton>
        <Typography>Back</Typography>
        <Typography style={{ marginLeft: 40 }}>
          Analyzing Metric: {metric.metricName}
        </Typography>
      </div>
      <Card style={{ width: "90%", marginTop: 20 }}>
        {!charts && query.active && <Loader />}
        {charts && (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div />
              <ChartToolbar
                tool={tool}
                setTool={setTool}
                chart={chartRef.current}
              />
            </div>
            <Line
              data={charts}
              height={500}
              ref={chartRef}
              options={{
                plugins: {
                  zoom: {
                    pan: {
                      enabled: tool === "PAN",
                      mode: "xy",
                      rangeMin: {
                        x: null,
                        y: null,
                      },
                      rangeMax: {
                        x: null,
                        y: null,
                      },
                      speed: 20,
                      threshold: 10,
                    },
                    zoom: {
                      enabled: tool === "ZOOM",
                      drag: true,
                      mode: "xy",
                      speed: 0.1,
                      threshold: 2,
                      sensitivity: 3,
                    },
                  },
                },
                legend: {
                  labels: {
                    fontColor:
                      currentTheme.theme === "dark" ? "white" : "black",
                    boxWidth: 10,
                    fontSize: 12,
                  },
                },
                hover: {
                  mode: "nearest",
                  interset: true,
                },
                tooltips: {
                  mode: "index",
                  intersect: false,
                },
                scales: {
                  xAxes: [
                    {
                      type: "time",
                      time: {
                        unit: "day",
                      },
                      ticks: {
                        fontColor: currentTheme.theme === "dark" && "lightgray",
                      },
                      gridLines: {
                        zeroLineColor:
                          currentTheme.theme === "dark" && "lightgray",
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        fontColor: currentTheme.theme === "dark" && "lightgray",
                      },
                      gridLines: {
                        zeroLineColor:
                          currentTheme.theme === "dark" && "lightgray",
                      },
                    },
                  ],
                },
                maintainAspectRatio: false,
              }}
            />
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
}

export default ForecastContainer;
