import React, { useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  useTheme
} from "@material-ui/core";
import { defaultChartOptions } from "../../../../../../services/ChartingService";
import { primaryDark } from "../../../../../../styles/colors";
import { useMemo } from "react";
import { getMaxColor, isInBusinessHours } from "../../../../../../utils";
import { ThemeContext, ProjectContext } from "../../../../../../contextStore";
import { Doughnut } from "react-chartjs-2";

const useStyles = makeStyles(() => ({
  container: {
    minWidth: 300
  },
  radialDescription: {
    textAlign: "center"
  }
}));

const COLORS = [
  "#19d0e6",
  "#1068ef",
  "#6CDB9C",
  "#5dadec",
  "#bb35ca",
  "#FF6666",
  "#447825",
  "#6CDB9C",
  "#25da57",
  "#A64CA6"
];

function MetricDial({
  metricData,
  max,
  metric,
  index,
  mode,
  onlyInBusinessHours
}) {
  const currentTheme = useContext(ThemeContext);
  const { currentProject } = useContext(ProjectContext);

  const classes = useStyles();
  let value = useMemo(() => {
    if (!metricData.length) return 0;
    let startTime;
    let endTime;

    const { businessHours } = currentProject;

    if (businessHours) {
      [startTime, endTime] = businessHours.split("-");
    }

    const filteredData = metricData.filter(item => {
      const { timestamp } = item;
      if (onlyInBusinessHours) {
        const inHours = isInBusinessHours(startTime, endTime, timestamp);
        if (inHours) return true;
        return false;
      }
      return true;
    });
    if (!metricData.length || !filteredData.length) {
      return 0;
    }
    if (mode === "LIVE") {
      if (!metricData.length || !filteredData.length) {
        return 0;
      }

      const value = filteredData[filteredData.length - 1].value.live;
      return value < 0 ? 0 : value;
    }
    if (mode === "AVERAGE") {
      const sum = filteredData.reduce(
        (accum, val) => (accum += val.value.live),
        0
      );

      const value = Math.floor(sum / filteredData.length);
      return value < 0 ? 0 : value;
    }

    if (mode === "MAX") {
      const values = filteredData.map(item => item.value.max);
      const value = Math.max(...values);
      return value < 0 ? 0 : value;
    }
  }, [mode, metricData, onlyInBusinessHours]);

  const pct = Math.floor((value / max) * 100);

  const data = {
    labels: [metric],
    datasets: [
      {
        weight: 0.2,
        data: [pct, 100 - pct],
        backgroundColor: [
          COLORS[index],
          currentTheme.theme === "dark" ? "#323232" : "lightgray"
        ],
        hoverBackgroundColor: [
          COLORS[index],
          currentTheme.theme === "dark" ? "#323232" : "lightgray"
        ],
        borderColor: currentTheme.theme === "dark" ? "#323232" : "lightgray",
        borderWidth: 1
      }
    ]
  };

  return (
    <React.Fragment>
      <Doughnut
        data={data}
        height={200}
        options={{
          cutoutPercentage: 70,
          legend: {
            labels: {
              fontColor: currentTheme.theme === "dark" ? "white" : "black",
              boxWidth: 0,
              fontSize: 14
            }
          }
        }}
      />
      <Typography
        style={{
          alignSelf: "center",
          position: "relative",
          bottom: 100,
          fontSize: 24
        }}
      >
        {pct}%
      </Typography>
      <Typography style={{ alignSelf: "center", marginTop: 20 }}>
        {value} / {max}
      </Typography>
    </React.Fragment>
  );
}

export default MetricDial;
