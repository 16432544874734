import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  makeStyles,
  DialogActions,
  Button,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { useFormInput } from "../../utils/hooks";
import { useState } from "react";
import { createDevice } from "../../services/DeviceService";
import { useContext } from "react";
import { ProjectContext, QueryContext } from "../../contextStore";
import { patchItem } from "../../services/DbService";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    marginTop: 20,
    fontWeight: "bold",
    textAlign: "center"
  },
  counterTitle: {
    marginTop: 20
  },
  row: {
    margin: "10px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  deviceNameInput: {
    marginTop: 20,
    marginBottom: 10
  },
  deviceModelInput: {
    marginTop: 10,
    marginBottom: 10
  },
  leftAlignedInput: {
    marginRight: 20
  },
  createButton: {
    alignSelf: "flex-end",
    marginTop: 10
  },
  spinner: {
    margin: 40
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

function DeviceDialog({ handleClose, handleAddDevice, device }) {
  const actualDevice = typeof device === "boolean" ? false : device;
  const classes = useStyles();
  const deviceName = useFormInput(actualDevice ? actualDevice.name : "");
  const deviceModel = useFormInput(actualDevice ? actualDevice.model : "");
  const ip = useFormInput(
    actualDevice ? actualDevice.ipPort.split(":")[0] : ""
  );
  const port = useFormInput(
    actualDevice ? actualDevice.ipPort.split(":")[1] : ""
  );
  const username = useFormInput(actualDevice ? actualDevice.username : "");
  const password = useFormInput(actualDevice ? actualDevice.password : "");
  const { currentProject } = useContext(ProjectContext);
  const query = useContext(QueryContext);

  function isDisabled() {
    if (
      !deviceName.value.length ||
      !deviceModel.value.length ||
      !ip.value.length ||
      !port.value.length ||
      !username.value.length ||
      !password.value.length
    ) {
      return true;
    }

    return false;
  }

  async function handleAddDeviceClick() {
    query.show();
    let newDevice = {};

    if (actualDevice) {
      newDevice = { ...actualDevice };
    }

    newDevice = {
      ...newDevice,
      name: deviceName.value,
      model: deviceModel.value,
      ipPort: `${ip.value}:${port.value}`,
      username: username.value,
      password: password.value,
      projectId: currentProject.projectId
    };

    try {
      if (!actualDevice) {
        const deviceResponse = await createDevice(newDevice);
        handleAddDevice(deviceResponse);
      } else {
        await patchItem(newDevice, newDevice.deviceId, "deviceId", "device");
        handleAddDevice(newDevice);
      }
      query.hide();
    } catch (e) {
      query.hide();
      alert("Error creating device");
    }
  }

  return (
    <Dialog fullWidth open>
      <DialogTitle>Device Configuration</DialogTitle>
      <DialogContent className={classes.content}>
        <React.Fragment>
          <TextField
            fullWidth
            {...deviceName}
            label="Device Name"
            color="secondary"
            variant="filled"
            className={classes.deviceNameInput}
          />
          <div className={classes.row}>
            <TextField
              className={classes.leftAlignedInput}
              fullWidth
              {...ip}
              label="IP Address"
              variant="filled"
              color="secondary"
            />
            <TextField
              color="secondary"
              fullWidth
              {...port}
              label="Port"
              variant="filled"
            />
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.leftAlignedInput}
              fullWidth
              {...username}
              label="Username"
              variant="filled"
              color="secondary"
            />
            <TextField
              color="secondary"
              fullWidth
              {...password}
              label="Password"
              variant="filled"
            />
          </div>
          <TextField
            fullWidth
            {...deviceModel}
            label="Device Model"
            variant="filled"
            color="secondary"
            className={classes.deviceModelInput}
          />
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={handleAddDeviceClick}
          variant="contained"
          color="secondary"
        >
          Add Device
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeviceDialog;
