import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  IconButton
} from "@material-ui/core";
import { Edit, Add, Delete } from "@material-ui/icons";
import { useContext } from "react";
import {
  CounterContext,
  QueryContext,
  ThemeContext
} from "../../../../contextStore";
import { getTableRowStyle } from "../../../../utils";
import { useDialog } from "../../../../utils/hooks";
import CounterDialog from "../../../../components/CounterDialog";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import { deleteItem } from "../../../../services/DbService";

const useStyles = makeStyles(() => ({
  container: {
    margin: 20
  },
  title: {
    marginBottom: 20
  },

  addItem: {
    "&:hover": {
      background: "rgba(255,255,255,.1)"
    }
  }
}));

function CounterList() {
  const classes = useStyles();
  const { counters, updateCounters } = useContext(CounterContext);
  const { theme } = useContext(ThemeContext);
  const minWidthMatches = useMediaQuery("(max-width:900px)");
  const addCounterDialog = useDialog();
  const query = useContext(QueryContext);
  const deleteDialog = useDialog();

  function handleAddCounter(newCounter) {
    updateCounters(newCounter);
    addCounterDialog.close();
  }

  async function handleDeleteCounter() {
    query.show();
    try {
      await deleteItem(deleteDialog.open.counterId, "counterId", "counter");
      updateCounters(deleteDialog.open, true);
      deleteDialog.close();
      query.hide();
    } catch (e) {
      alert("Error deleting counter");
      query.hide();
    }
  }

  return (
    <div className={classes.container}>
      {addCounterDialog.open && (
        <CounterDialog
          counter={addCounterDialog.open}
          handleClose={() => addCounterDialog.close()}
          handleAdd={handleAddCounter}
        />
      )}
      {deleteDialog.open && (
        <ConfirmationDialog
          handleSubmit={handleDeleteCounter}
          handleClose={() => deleteDialog.close()}
          title="Delete Counter"
          content="Are you sure you would like to delete this counter?"
          submitText="Delete"
        />
      )}

      <Card>
        <CardContent>
          {!minWidthMatches && (
            <React.Fragment>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Identifier</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell padding="checkbox">Actions</TableCell>
                    <TableCell padding="checkbox" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {counters.map((counter, counterIdx) => (
                    <TableRow
                      style={getTableRowStyle(counterIdx, theme)}
                      key={counter.counterId}
                    >
                      <TableCell>{counter.mnemonic}</TableCell>
                      <TableCell>{counter.name}</TableCell>
                      <TableCell>{counter.value}</TableCell>
                      <TableCell>
                        {counter.lastUpdate
                          ? new Date(counter.lastUpdate).toLocaleString()
                          : "-"}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton
                          onClick={() => addCounterDialog.show(counter)}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton onClick={() => deleteDialog.show(counter)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div
                onClick={() => addCounterDialog.show()}
                style={{
                  width: "100%",
                  height: 50,
                  border: "1px dashed gray",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row"
                }}
                className={classes.addItem}
              >
                <Add style={{ marginRight: 10 }} />
                <Typography>Add Counter</Typography>
              </div>
            </React.Fragment>
          )}
          {minWidthMatches && (
            <List>
              {counters.map(counter => (
                <React.Fragment>
                  <ListItem key={counter.counterId}>
                    <ListItemText primary={`Name: ${counter.mnemonic}`} />
                  </ListItem>
                  <ListItem key={counter.counterId}>
                    <ListItemText primary={`Identifier: ${counter.name}`} />
                  </ListItem>
                  <ListItem divider key={counter.counterId}>
                    <ListItemText primary={`Value: ${counter.value}`} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default CounterList;
