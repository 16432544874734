import React from "react";
import Lottie from "react-lottie";
import loader from "../../animations/loader1.json";
import { Fade } from "@material-ui/core";

function HashpleLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Fade in>
      <Lottie
        options={defaultOptions}
        height={300}
        width={300}
        style={{ position: "absolute", top: "30%" }}
      />
    </Fade>
  );
}

export default HashpleLoader;
