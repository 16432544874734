import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  makeStyles,
  DialogActions,
  Button
} from "@material-ui/core";
import { useFormInput } from "../../utils/hooks";
import { useContext } from "react";
import {
  ProjectContext,
  QueryContext,
  CounterContext
} from "../../contextStore";
import { createCounter } from "../../services/CounterService";
import { v1 } from "uuid";
import { createItem, patchItem } from "../../services/DbService";
import { getRealTime, convertToBusinessHours } from "../../utils";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    marginTop: 20,
    fontWeight: "bold",
    textAlign: "center"
  },
  counterTitle: {
    marginTop: 20
  },
  row: {
    margin: "10px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  deviceNameInput: {
    marginTop: 20,
    marginBottom: 10
  },
  deviceModelInput: {
    marginTop: 10,
    marginBottom: 10
  },
  leftAlignedInput: {
    marginRight: 20
  },
  createButton: {
    alignSelf: "flex-end",
    marginTop: 10
  },
  spinner: {
    margin: 40
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

function ProjectDialog({ handleClose, handleAdd, project }) {
  const actual = typeof project === "boolean" ? false : project;
  const classes = useStyles();
  const name = useFormInput(actual ? actual.name : "");
  const start = useFormInput(
    actual ? getRealTime(actual.businessHours.split("-")[0]) : ""
  );
  const end = useFormInput(
    actual ? getRealTime(actual.businessHours.split("-")[1]) : ""
  );
  const query = useContext(QueryContext);

  function isDisabled() {
    if (!name.value.length || !start.value.length || !end.value.length) {
      return true;
    }

    return false;
  }

  async function handleAddClick() {
    query.show();
    let newActual = {};

    if (actual) {
      newActual = { ...actual };
    }

    const convertedBusinessHours = convertToBusinessHours(
      start.value,
      end.value
    );

    newActual = {
      ...newActual,
      name: name.value,
      businessHours: convertedBusinessHours
    };

    if (!actual) {
      newActual.projectId = v1().toUpperCase();
    }

    try {
      if (!actual) {
        const response = await createItem(newActual, "projects");
        handleAdd(response);
      } else {
        await patchItem(
          newActual,
          newActual.projectId,
          "projectId",
          "projects"
        );
        handleAdd(newActual);
      }
      query.hide();
    } catch (e) {
      query.hide();
      alert("Error creating project");
    }
  }

  return (
    <Dialog fullWidth open>
      <DialogTitle>Device Configuration</DialogTitle>
      <DialogContent className={classes.content}>
        <React.Fragment>
          <TextField
            fullWidth
            {...name}
            label="Name"
            color="secondary"
            variant="filled"
            className={classes.deviceNameInput}
          />
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <TextField
              fullWidth
              {...start}
              label="From"
              type="time"
              color="secondary"
              variant="filled"
              className={classes.deviceNameInput}
              style={{ marginRight: 20 }}
            />
            <TextField
              fullWidth
              {...end}
              label="From"
              type="time"
              color="secondary"
              variant="filled"
              className={classes.deviceNameInput}
            />
          </div>
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={handleAddClick}
          variant="contained"
          color="secondary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProjectDialog;
