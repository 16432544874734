import React from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import { useFormInput } from "../../utils/hooks";
import { signInUser, signUpUser } from "../../services/CognitoService";
import { createCustomer } from "../../services/CustomerService";

const useStyles = makeStyles(() => ({
  card: {
    width: "90%",
    maxWidth: 500,
    marginTop: 40,
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    alignSelf: "flex-start",
    fontWeight: "bold",
  },
  input: {
    marginTop: 20,
  },
  actionButton: {
    alignSelf: "flex-end",
    marginTop: 20,
  },
}));

function AuthPage({ authType }) {
  const classes = useStyles();

  const email = useFormInput("");
  const password = useFormInput("");
  const name = useFormInput("");

  async function onActionButtonClick() {
    if (!email.value || !password.value) {
      alert("Please enter email and or password");
      return;
    }

    if (authType === "Login") {
      try {
        await signInUser({ email: email.value, password: password.value });
        window.location.href = "/";
      } catch (e) {
        console.log("ERror: ", e);
        alert("There was an error login in");
      }
    } else {
      try {
        const resp = await signUpUser(email.value, password.value);
        const { userSub } = resp;
        await createCustomer({
          email: email.value,
          sub: userSub,
          name: name.value,
        });
        console.log("resp: ", resp);
        await signInUser({ email: email.value, password: password.value });
        window.location.href = "/";
      } catch (e) {
        alert("There was an error registering");
      }
    }
  }

  return (
    <PageWrapper fullHeight horizontalCenter>
      <Card className={classes.card}>
        <CardContent className={classes.container}>
          <Typography className={classes.title}>Welcome to Hashple</Typography>
          {authType === "Register" && (
            <TextField
              className={classes.input}
              fullWidth
              variant="filled"
              label="Name"
              color="secondary"
              {...name}
            />
          )}
          <TextField
            className={classes.input}
            fullWidth
            variant="filled"
            label="Email"
            color="secondary"
            {...email}
          />
          <TextField
            className={classes.input}
            fullWidth
            variant="filled"
            label="Password"
            color="secondary"
            type="password"
            {...password}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={onActionButtonClick}
            className={classes.actionButton}
            disabled={!email.value || !password.value}
          >
            {authType}
          </Button>
        </CardContent>
      </Card>
    </PageWrapper>
  );
}

export default AuthPage;
