import React, { useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  useMediaQuery,
  ListItem,
  ListItemText,
  List
} from "@material-ui/core";
import { useContext } from "react";
import { MetricsContext } from "../../../../contextStore";

const useStyles = makeStyles(() => ({
  container: {
    margin: 20
  },
  title: {
    marginBottom: 20
  }
}));

function MetricList() {
  const classes = useStyles();
  const { metrics, refreshMetrics } = useContext(MetricsContext);
  const minWidthMatches = useMediaQuery("(max-width:900px)");
  const refreshInterval = useRef(false);

  useEffect(() => {
    refreshInterval.current = setInterval(() => {
      refreshMetrics();
    }, 10000);

    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
        refreshInterval.current = false;
      }
    };
  }, []);

  return (
    <div className={classes.container}>
      <Card>
        <CardContent>
          {!minWidthMatches && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Expression</TableCell>
                  <TableCell>Max</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metrics.map(metric => (
                  <TableRow key={metric.metricId}>
                    <TableCell>{metric.metricName}</TableCell>
                    <TableCell>{metric.expression}</TableCell>
                    <TableCell>{metric.max}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {minWidthMatches && (
            <List>
              {metrics.map(metric => (
                <React.Fragment>
                  <ListItem key={metric.metricId}>
                    <ListItemText primary={`Name: ${metric.metricName}`} />
                  </ListItem>
                  <ListItem key={metric.metricId}>
                    <ListItemText
                      primary={`Expression: ${metric.expression}`}
                    />
                  </ListItem>
                  <ListItem divider key={metric.metricId}>
                    <ListItemText primary={`Max: ${metric.max}`} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default MetricList;
