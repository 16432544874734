import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

function ConfirmationDialog({
  title,
  content,
  children,
  handleClose,
  handleSubmit,
  submitText = "Submit"
}) {
  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
