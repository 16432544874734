import React, { useState, useContext } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Card, CardContent, Typography, Tabs, Tab } from "@material-ui/core";
import DeviceList from "./components/DeviceList";
import CounterList from "./components/CounterList";
import MetricList from "./components/MetricList";
import ActionList from "./components/ActionList";
import { ThemeContext } from "../../contextStore";
import ProfilePage from "../ProfilePage";
import ProjectList from "../../components/ProjectList";

const paths = {
  "/settings": 0,
  "/settings/actions": 0,
  "/settings/devices": 1,
  "/settings/counters": 2,
  "/settings/metrics": 3,
  "/settings/projects": 4,
  "/settings/profile": 5
};

const pathsArr = [
  "/settings/actions",
  "/settings/devices",
  "/settings/counters",
  "/settings/metrics",
  "/settings/projects",
  "/settings/profile"
];

function getInitialTab() {
  const { pathname } = window.location;
  return paths[pathname];
}

function ConfigurationPage() {
  const [currentTab, setCurrentTab] = useState(getInitialTab());
  const { theme } = useContext(ThemeContext);

  const tabStyle = { color: theme === "light" ? "black" : "white" };
  return (
    <PageWrapper>
      <Tabs
        value={currentTab}
        onChange={(_, val) => {
          window.history.pushState(null, "", pathsArr[val]);
          setCurrentTab(val);
        }}
        variant="scrollable"
        scrollButtons="on"
        TabScrollButtonProps={{
          style: { color: theme === "dark" ? "white" : "black" }
        }}
        style={{
          marginTop: 20,
          borderBottom: "0.5px solid gray"
        }}
      >
        <Tab style={tabStyle} label="Actions" />
        <Tab style={tabStyle} label="Devices" />
        <Tab style={tabStyle} label="Counters" />
        <Tab style={tabStyle} label="Metrics" />
        <Tab style={tabStyle} label="Projects" />
        <Tab style={tabStyle} label="Profile" />
      </Tabs>
      {currentTab == 0 && <ActionList />}
      {currentTab === 1 && <DeviceList />}
      {currentTab === 2 && <CounterList />}
      {currentTab === 3 && <MetricList />}
      {currentTab === 4 && <ProjectList />}
      {currentTab === 5 && <ProfilePage />}
    </PageWrapper>
  );
}

export default ConfigurationPage;
