import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  makeStyles,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import ProjectSelector from "../../pages/HomePage/components/ProjectSelector";
import { useContext } from "react";
import { ProjectContext, UserContext, ThemeContext } from "../../contextStore";
import { ExitToApp, Menu } from "@material-ui/icons";
import { signOut } from "../../services/CognitoService";
import { useHistory } from "react-router-dom";
import { APP_VERSION } from "../../config.js";
import SideBar from "../SideBar";
import { ReactComponent as LogoLng } from "../../assets/logo.svg";

const useStyles = makeStyles(() => ({
  title: {
    cursor: "pointer"
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 50,
    height: 50,
    paddingLeft: 20
  },
  container: {
    zIndex: 2
  },
  loginButton: {
    marginRight: 10
  }
}));

const logo = require("../../assets/logo.png");
function NavBar() {
  const classes = useStyles();
  const { projects, currentProject } = useContext(ProjectContext);
  const user = useContext(UserContext);
  const history = useHistory();
  const minWidthMenu = useMediaQuery("(min-width:800px)");
  const [menuOpen, setMenuOpen] = useState(false);
  const currentTheme = useContext(ThemeContext);

  function handleLoginClick() {
    history.push("/login");
  }
  return (
    <React.Fragment>
      <AppBar
        className={classes.container}
        position="fixed"
        style={{ backgroundColor: currentTheme.theme === "light" && "white" }}
      >
        <Toolbar disableGutters className={classes.toolbar}>
          {minWidthMenu ? (
            <React.Fragment>
              <img
                src={require("../../assets/hashple_logo_full.png")}
                height={20}
                style={{
                  filter: currentTheme.theme === "light" && "brightness(0.6)"
                }}
              />
              <Typography
                variant="caption"
                style={{
                  marginLeft: 10
                }}
              >
                v{APP_VERSION}
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {user && (
                <React.Fragment>
                  <IconButton
                    onClick={() => setMenuOpen(true)}
                    style={{ paddingLeft: 0 }}
                  >
                    <Menu />
                  </IconButton>
                  <Typography variant="caption" style={{ marginLeft: 10 }}>
                    v{APP_VERSION}
                  </Typography>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {/* <Typography variant="h6" className={classes.title}>
            C
          </Typography> */}
          {user && projects && !!projects.length && (
            <ProjectSelector
              projects={projects}
              currentProject={currentProject}
            />
          )}
        </Toolbar>
      </AppBar>
      {user && <SideBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />}
    </React.Fragment>
  );
}

export default NavBar;
