import React from "react";
import PageWrapper from "../PageWrapper";
import { Typography, makeStyles } from "@material-ui/core";
import CreateNewProject from "../../pages/HomePage/components/CreateNewProject";

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 40,
  },
  container: {
    display: "flex",
    width: "80%",
    maxWidth: 500,
    flexDirection: "column",
  },
}));
function CreateProjectView() {
  const classes = useStyles();
  return (
    <PageWrapper horizontalCenter fullHeight>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h6">
          Create New Project
        </Typography>
        <CreateNewProject />
      </div>
    </PageWrapper>
  );
}

export default CreateProjectView;
