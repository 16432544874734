import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const customerUrl = `${apiEndpoint}/customer`;

export async function createCustomer({ email, sub, name, parent }) {
  const body = {
    email,
    sub,
    name,
  };

  if (parent) {
    body.parent = parent;
  }
  try {
    await HttpService.post(customerUrl, body);
  } catch (e) {
    throw e;
  }
}

export async function getCustomer() {
  try {
    const response = await HttpService.get(customerUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function patchUser(body) {
  try {
    const response = await HttpService.patch(customerUrl, body);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getSubAccounts() {
  try {
    const response = await HttpService.get(`${customerUrl}/subaccounts`);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
