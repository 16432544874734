import React from "react";

export const UserContext = React.createContext();
export const ProjectContext = React.createContext();
export const ViewContext = React.createContext();
export const DeviceContext = React.createContext();
export const CounterContext = React.createContext();
export const ActionsContext = React.createContext();
export const MetricsContext = React.createContext();
export const QueryContext = React.createContext();
export const ThemeContext = React.createContext();
