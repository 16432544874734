import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Switch,
  IconButton,
  TableSortLabel
} from "@material-ui/core";
import { useContext } from "react";
import {
  DeviceContext,
  QueryContext,
  ThemeContext
} from "../../../../contextStore";
import { mainYellow } from "../../../../styles/colors";
import { Edit, Delete, Add } from "@material-ui/icons";
import { useDialog, useSort } from "../../../../utils/hooks";
import { getTableRowStyle, sortArray } from "../../../../utils";
import DeviceDialog from "../../../../components/DeviceDialog";
import { deleteDevice } from "../../../../services/DeviceService";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import { patchItem } from "../../../../services/DbService";

const useStyles = makeStyles(() => ({
  container: {
    margin: 20
  },
  trackOn: {
    background: mainYellow
  },
  trackOff: {
    background: "indianred"
  },
  switchOff: {
    background: "indianred"
  },
  title: {
    marginBottom: 20
  },
  addItem: {
    "&:hover": {
      background: "rgba(255,255,255,.1)"
    }
  }
}));

function DeviceList() {
  const classes = useStyles();
  const { devices, updateDevices, refreshDevices } = useContext(DeviceContext);
  const { theme } = useContext(ThemeContext);
  const minWidthMatches = useMediaQuery("(max-width:900px)");
  const deviceDialog = useDialog(false);
  const deleteDialog = useDialog();
  const query = useContext(QueryContext);
  const refreshInterval = useRef(false);
  const [sortBy, setSortBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [sorted, setSorted] = useState(sortArray(devices, order, sortBy));

  function flipSortBy(newSort) {
    const newOrder = order === "asc" ? "desc" : "asc";
    setSortBy(newSort);
    setOrder(newOrder);
    setSorted(sortArray(devices, newOrder, newSort));
  }

  useEffect(() => {
    setSorted(devices, order, sortBy);
  }, [devices]);

  console.log("sorted: ", sorted);
  useEffect(() => {
    refreshInterval.current = setInterval(() => {
      refreshDevices();
    }, 10000);

    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
        refreshInterval.current = false;
      }
    };
  }, []);

  function handleAddDevice(newDevice) {
    updateDevices(newDevice);
    deviceDialog.close();
  }

  async function handleSwitchClick(device) {
    query.show();
    const newDevice = {
      ...device,
      off: !device.off
    };

    try {
      await patchItem(
        { off: !device.off },
        device.deviceId,
        "deviceId",
        "device"
      );
      updateDevices(newDevice);
      query.hide();
    } catch (e) {
      console.log("Error updating action", e);
      alert("Error updating action: ");
    }
  }

  async function handleDeleteDevice() {
    query.show();
    try {
      await deleteDevice(deleteDialog.open.deviceId);
      updateDevices(deleteDialog.open, true);
      deleteDialog.close();
      query.hide();
    } catch (e) {
      alert("Error deleting device");
      query.hide();
    }
  }

  return (
    <div className={classes.container}>
      {deviceDialog.open && (
        <DeviceDialog
          device={deviceDialog.open}
          handleClose={() => deviceDialog.close()}
          handleAddDevice={handleAddDevice}
        />
      )}
      {deleteDialog.open && (
        <ConfirmationDialog
          handleSubmit={handleDeleteDevice}
          handleClose={() => deleteDialog.close()}
          title="Delete Device"
          content="Are you sure you would like to delete this device?"
          submitText="Delete"
        />
      )}
      <Card>
        <CardContent>
          {!minWidthMatches && (
            <React.Fragment>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "name"}
                        direction={order}
                        onClick={() => flipSortBy("name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "ipPort"}
                        direction={order}
                        onClick={() => flipSortBy("ipPort")}
                      >
                        IP
                      </TableSortLabel>
                    </TableCell>

                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "ipPort"}
                        direction={order}
                        onClick={() => flipSortBy("ipPort")}
                      >
                        Port
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "username"}
                        direction={order}
                        onClick={() => flipSortBy("username")}
                      >
                        Username
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Password</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "model"}
                        direction={order}
                        onClick={() => flipSortBy("model")}
                      >
                        Model
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "lastConnected"}
                        direction={order}
                        onClick={() => flipSortBy("lastConnected")}
                      >
                        Last Connected
                      </TableSortLabel>
                    </TableCell>
                    <TableCell padding="checkbox">Actions</TableCell>
                    <TableCell padding="checkbox" />
                    <TableCell padding="checkbox" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sorted.map((device, deviceIdx) => (
                    <TableRow
                      style={getTableRowStyle(deviceIdx, theme)}
                      key={device.deviceId}
                    >
                      <TableCell>{device.name}</TableCell>
                      <TableCell>{device.ipPort.split(":")[0]}</TableCell>
                      <TableCell>{device.ipPort.split(":")[1]}</TableCell>
                      <TableCell>{device.username}</TableCell>
                      <TableCell>*****</TableCell>
                      <TableCell>{device.model}</TableCell>
                      <TableCell>
                        {device.lastConnected
                          ? new Date(device.lastConnected).toLocaleString()
                          : "-"}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Switch
                          size="small"
                          onClick={() => handleSwitchClick(device)}
                          classes={{
                            track: device.off && classes.trackOff,
                            thumb: device.off && classes.trackOff
                          }}
                          checked={!device.off}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton
                          onClick={() => deviceDialog.show(device)}
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <IconButton
                          onClick={() => deleteDialog.show(device)}
                          size="small"
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div
                onClick={() => deviceDialog.show()}
                style={{
                  width: "100%",
                  height: 50,
                  border: "1px dashed gray",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row"
                }}
                className={classes.addItem}
              >
                <Add style={{ marginRight: 10 }} />
                <Typography>Add Device</Typography>
              </div>
            </React.Fragment>
          )}
          {minWidthMatches && (
            <List>
              {devices.map(device => (
                <React.Fragment>
                  <ListItem key={device.deviceId}>
                    <ListItemText
                      style={{ flexGrow: 0, marginRight: 20 }}
                      primary="Active:"
                    />
                    <Switch
                      size="small"
                      onClick={() => handleSwitchClick(device)}
                      classes={{
                        track: device.off && classes.trackOff,
                        thumb: device.off && classes.trackOff
                      }}
                      checked={!device.off}
                    />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText primary={`Name: ${device.name}`} />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText
                      primary={`IP: ${device.ipPort.split(":")[0]}`}
                    />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText
                      primary={`Port: ${device.ipPort.split(":")[1]}`}
                    />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText primary={`Username: ${device.username}`} />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText primary={`Password: ******`} />
                  </ListItem>
                  <ListItem key={device.deviceId}>
                    <ListItemText primary={`Model: ${device.model}`} />
                  </ListItem>
                  <ListItem divider key={device.deviceId}>
                    <ListItemText
                      primary={`Last Connected: ${
                        device.lastConnected
                          ? new Date(device.lastConnected).toLocaleString()
                          : "-"
                      }`}
                    />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default DeviceList;
