import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { Typography } from "@material-ui/core";

function AlarmsPage() {
  return (
    <PageWrapper fullHeight verticalCenter horizontalCenter>
      <Typography>Coming Soon...</Typography>
    </PageWrapper>
  );
}

export default AlarmsPage;
