import React, { useEffect, useContext } from "react";
import { Typography, NativeSelect } from "@material-ui/core";
import AceEditor from "react-ace";
import { addCompleter } from "ace-builds/src-noconflict/ext-language_tools";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/theme-tomorrow_night";
import { mainYellow } from "../../styles/colors";
import { myCompleter, Editor_Examples, ifThenOptions } from "../../utils";
import {
  MetricsContext,
  DeviceContext,
  CounterContext
} from "../../contextStore";

function HashpleEditor({
  action,
  actionType,
  handleActionChange,
  placeholder
}) {
  const { metrics } = useContext(MetricsContext);
  const { devices } = useContext(DeviceContext);
  const { counters } = useContext(CounterContext);

  useEffect(() => {
    const metricList = metrics.map(metric => ({
      method: metric.metricName,
      desc: "Metric"
    }));

    const deviceList = devices.map(device => ({
      method: device.name,
      desc: "Device"
    }));
    const countersList = counters.map(counter => ({
      method: counter.name,
      desc: "Counter"
    }));

    console.log("metricList: ", metricList);
    addCompleter(myCompleter([...metricList, ...deviceList, ...countersList]));
  }, []);

  function handleExampleChange({ target }) {
    const { value } = target;
    if (value !== "default") {
      handleActionChange(Editor_Examples[value]);
    }
  }

  return (
    <React.Fragment>
      <NativeSelect style={{ marginTop: 10 }} onChange={handleExampleChange}>
        <option value={"default"}>Examples</option>
        {actionType === "IF" && (
          <option value={"last_metric_gt"}>
            Last value of metric greater than SLA
          </option>
        )}
        {actionType !== "IF" &&
          ifThenOptions.map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
      </NativeSelect>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            width: "45px",
            background: actionType === "ELSE" ? "indianred" : mainYellow,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black"
          }}
        >
          {actionType}
        </Typography>
        <AceEditor
          mode="javascript"
          theme="terminal"
          name="name"
          value={action}
          placeholder={placeholder}
          height="100px"
          onChange={handleActionChange}
          width="100%"
          style={{
            borderLeft: `1px solid ${
              actionType === "ELSE" ? "indianred" : mainYellow
            }`
          }}
          highlightActiveLine={false}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default HashpleEditor;
