import React, { useContext, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  Slide,
  Button,
  IconButton
} from "@material-ui/core";
import { defaultChartOptions } from "../../../../../../services/ChartingService";
import { useMemo } from "react";
import {
  hexToRgb,
  getRealTime,
  isInBusinessHours,
  chunkArrayInGroups
} from "../../../../../../utils";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";
import { primaryDark } from "../../../../../../styles/colors";
import { ThemeContext, ProjectContext } from "../../../../../../contextStore";
import { useWindowSize } from "../../../../../../utils/hooks";
import "chartjs-plugin-zoom";
import "chartjs-plugin-annotation";
import "hammerjs";
import ChartToolbar from "../../../../../../components/ChartToolbar";
import {
  getZoomPlugin,
  createBoxAnnotation,
  createLineAnnotation
} from "../../../../../../utils/chartUtils";

const COLORS = [
  "#19d0e6",
  "#1068ef",
  "#6CDB9C",
  "#5dadec",
  "#bb35ca",
  "#FF6666",
  "#447825",
  "#6CDB9C",
  "#25da57",
  "#A64CA6"
];

const HistoricMetricWidget = ({
  metric,
  metricData,
  index,
  time,
  onAnalyzeClick,
  onlyInBusinessHours
}) => {
  // const minWidthForToolbar = useMediaQuery("(min-width:600px)");
  const lineRef = useRef(false);
  const currentTheme = useContext(ThemeContext);
  const { width } = useWindowSize();
  const [tool, setTool] = useState("ZOOM");
  const { currentProject } = useContext(ProjectContext);

  const series = useMemo(() => {
    if (!metricData) return { result: [], labels: [] };

    const lastItem = metricData[metricData.length - 1].timestamp;
    let last = moment(lastItem).endOf(!time ? "minute" : "hour");

    const endOf = moment()
      .endOf("day")
      .endOf(!time ? "minute" : "hour");

    const labelsAfter = [];
    const resultAfter = [];

    let stillLeft = true;
    while (stillLeft) {
      last = moment(last).add(!time ? "minute" : "hour", 1);
      labelsAfter.push(moment(last).toLocaleString());
      if (moment(last).isSameOrAfter(moment(endOf))) {
        stillLeft = false;
      }
    }

    const { businessHours } = currentProject;

    let result = [];
    let labels = [];

    let startTime;
    let endTime;

    if (businessHours) {
      [startTime, endTime] = businessHours.split("-");
    }

    metricData.forEach((item, idx) => {
      result.push(item.value.average);
      labels.push(moment(item.timestamp).toLocaleString());
    });

    result = [...result, ...resultAfter];
    labels = [...labels, ...labelsAfter];

    const realLabels = [];
    const realResult = [];

    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      const inHours = isInBusinessHours(startTime, endTime, label);
      if (onlyInBusinessHours && !inHours) continue;
      realLabels.push(label);
      realResult.push(result[i]);
    }

    return { result: realResult, labels: realLabels };
  }, [metricData, time, onlyInBusinessHours]);

  const data = canvas => {
    const ctx = canvas.getContext("2d");
    var gradient = ctx.createLinearGradient(0, 0, 0, 500);
    const start = hexToRgb(COLORS[index], 0.1);
    const end = hexToRgb(COLORS[index], 0.6);

    gradient.addColorStop(0, end);
    gradient.addColorStop(1, start);

    return {
      labels: series.labels,
      datasets: [
        {
          label: `${metric}`,
          fill: true,
          lineTension: 0.5,
          backgroundColor: gradient,
          borderColor: COLORS[index],
          borderCapStyle: "butt",
          borderDash: [],
          borderWidth: 2,
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: COLORS[index],
          pointBackgroundColor: COLORS[index],
          pointBorderWidth: 0.5,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: COLORS[index],
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 0.5,
          pointHitRadius: 20,
          data: series.result
        }
      ]
    };
  };

  const annotations = useMemo(() => {
    const res = [];
    const { labels } = series;

    if (!onlyInBusinessHours) {
      return res;
    }
    const splitLabels = chunkArrayInGroups(labels, !time ? 24 * 60 : 9);

    if (splitLabels.length < 1) {
      return res;
    }

    for (let i = 0; i < splitLabels.length; i++) {
      const split = splitLabels[i];
      const end = split[split.length - 1];
      res.push(createLineAnnotation(end, moment(end).format("MM/DD")));
    }

    res.pop();
    return res;
  }, [metricData, onlyInBusinessHours]);

  return (
    <Card
      style={{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={onAnalyzeClick}
            style={{ alignSelf: "flex-end", marginBottom: 20 }}
          >
            Analyze (Beta)
          </Button>
          <ChartToolbar chart={lineRef.current} tool={tool} setTool={setTool} />
        </div>
        <Line
          data={data}
          ref={lineRef}
          options={{
            annotation: {
              annotations
            },
            plugins: {
              zoom: getZoomPlugin(tool)
            },
            legend: {
              labels: {
                fontColor: currentTheme.theme === "dark" ? "white" : "black",
                boxWidth: 0,
                fontSize: 16
              }
            },
            hover: {
              mode: "nearest",
              interset: true
            },
            tooltips: {
              mode: "index",
              intersect: false
            },
            scales: {
              xAxes: [
                {
                  id: "x-axis-0",
                  type: "time",
                  time: {
                    unit:
                      !time ||
                      moment()
                        .subtract("day", 2)
                        .isBefore(time)
                        ? "hour"
                        : "day"
                  },
                  distribution: onlyInBusinessHours ? "series" : "linear",
                  ticks: {
                    maxRotation: 0,
                    autoSkipPadding: 10,
                    autoSkip: true,
                    beginAtZero: true,
                    fontColor: currentTheme.theme === "dark" && "lightgray",
                    callback: function(value, index, values) {
                      return value;
                    }
                  },
                  gridLines: {
                    zeroLineColor: currentTheme.theme === "dark" && "lightgray"
                  }
                }
              ],
              yAxes: [
                {
                  id: "y-axis-0",
                  ticks: {
                    fontColor: currentTheme.theme === "dark" && "lightgray"
                  },
                  gridLines: {
                    zeroLineColor: currentTheme.theme === "dark" && "lightgray"
                  }
                }
              ]
            },
            maintainAspectRatio: true
          }}
        />
      </CardContent>
    </Card>
  );
};

function deepCheck(prev, next) {
  return JSON.stringify(prev) !== JSON.stringify(next);
}

function areEqual(prevProps, nextProps) {
  const different =
    deepCheck(prevProps.metricData, nextProps.metricData) ||
    deepCheck(prevProps.metric, nextProps.metric) ||
    deepCheck(prevProps.index, nextProps.index) ||
    deepCheck(prevProps.onlyInBusinessHours, nextProps.onlyInBusinessHours);

  if (different) return false;
  return true;
}

export default React.memo(HistoricMetricWidget, areEqual);
