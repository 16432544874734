import React, { useContext, useMemo } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody
} from "@material-ui/core";
import { MetricsContext } from "../../contextStore";
import moment from "moment-timezone";
import ReactApexChart from "react-apexcharts";
import { defaultChartOptions } from "../../services/ChartingService";

const tableCell = {
  color: "black"
};

function PdfReport({
  reportTitle,
  from,
  to,
  tableData,
  timeSlots,
  operation,
  metricsToDisplay
}) {
  const { metrics } = useContext(MetricsContext);
  return (
    <div
      id="pdf_report"
      style={{
        position: "absolute",
        right: -1000,
        minWidth: 1000,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div>
        <Typography style={{ color: "black" }} variant="h5">
          {reportTitle}
        </Typography>
        <Typography style={{ color: "black" }}>
          {from} - {to}
        </Typography>
      </div>
      <LineChart
        metricData={tableData}
        metricsToDisplay={metricsToDisplay}
        metrics={metrics}
      />
      <div
        style={{
          marginLeft: 20,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          bottom: 20
        }}
      >
        <Typography style={{ color: "black", fontSize: 12 }}>
          {moment(timeSlots[0].label).format("MM/DD HH:mm")}
        </Typography>
        <Typography style={{ color: "black", fontSize: 12 }}>
          {moment(timeSlots[timeSlots.length - 1].label).format("MM/DD HH:mm")}
        </Typography>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small" style={tableCell}>
              Date
            </TableCell>
            {metricsToDisplay.map(metric => (
              <TableCell size="small" style={tableCell}>
                {metrics.find(item => item.metricId === metric).metricName} (
                {operation})
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {timeSlots.map(slot => (
            <TableRow>
              <TableCell style={tableCell} size="small">
                {moment(slot.label).format("DD-MM-YYYY HH:mm:ss")}
              </TableCell>
              {metricsToDisplay.map((metric, metricIdx) => (
                <TableCell style={tableCell} size="small">
                  {tableData[metricIdx][slot.label]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

const COLORS = [
  "#1068ef",
  "#447825",
  "#9100ff",
  "#25da57",
  "#bb35ca",
  "#5dadec",
  "#A64CA6",
  "#FF6666"
];

function LineChart({ metricData, metricsToDisplay, metrics }) {
  const options = {
    ...defaultChartOptions,
    chart: {
      ...defaultChartOptions.chart,
      background: "#ffffff",
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      line: {
        curve: "smooth"
      }
    },
    dataLabels: {
      enabled: false
    },

    markers: {
      size: 0,
      style: "full"
    },
    colors: COLORS,
    title: {
      text: "Historic Chart",
      align: "center"
    },
    stroke: {
      width: 1,
      curve: "smooth",
      lineCap: "round"
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.5,
        type: "horizontal",
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0.8,
        stops: [0, 90, 100]
      }
    },
    yaxis: {
      show: true,
      title: {
        text: "Value"
      }
    },
    xaxis: {
      datetimeUTC: true,
      type: "datetime",
      // tickAmount: 24,
      // tickPlacement: "on",
      labels: {
        show: false,
        showDuplicates: false,
        datetimeUTC: false,
        formatter: function(value, timestamp, index) {
          return moment(timestamp)
            .tz("Europe/Madrid")
            .format("HH:mm");
        }
      }
    },
    grid: {
      show: true,
      borderColor: "#B2B2B2"
    },
    theme: {
      mode: "light"
    }
  };

  const series = useMemo(() => {
    if (!metricData) return false;
    let result = [];

    for (let i = 0; i < metricData.length; i++) {
      const metric = metricData[i];
      const entries = Object.entries(metric);
      const metricFound = metrics.find(
        item => item.metricId === metricsToDisplay[i]
      );

      const end = [];
      for (let j = 0; j < entries.length; j++) {
        const [key, value] = entries[j];
        end.push([key, value]);
      }

      result.push({
        name: metricFound.metricName,
        data: end
      });
    }

    return result;
  }, [metricData]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="250"
    />
  );
}

export default PdfReport;
