import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Collapse,
  IconButton,
  NativeSelect,
  Checkbox,
} from "@material-ui/core";
import AceEditor from "react-ace";
import { addCompleter } from "ace-builds/src-noconflict/ext-language_tools";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/theme-tomorrow_night";
import { Add, Close } from "@material-ui/icons";
import {
  QueryContext,
  MetricsContext,
  DeviceContext,
  CounterContext,
} from "../../../../contextStore";
import { createAction } from "../../../../services/ActionService";
import { mainYellow } from "../../../../styles/colors";
import LabelWrapper from "../../../../components/LabelWrapper";
import {
  myCompleter,
  ifThenOptions,
  isThereErrorInCode,
  Editor_Examples,
} from "../../../../utils";
import { patchItem } from "../../../../services/DbService";

const codeTheme = "terminal";

function EditAction({ action, handleClose, updateAction, actionName }) {
  const query = useContext(QueryContext);

  const [ifAction, setIfAction] = useState(
    action.ifAction.split(";").join("\n")
  );
  const [thenAction, setThenAction] = useState(
    action.thenAction.split(";").join("\n")
  );
  const [elseAction, setElseAction] = useState(
    action.elseAction ? action.elseAction.split(";").join("\n") : ""
  );

  const { metrics } = useContext(MetricsContext);
  const { devices } = useContext(DeviceContext);
  const { counters } = useContext(CounterContext);
  const [useElse, setUseElse] = useState(!!action.elseAction);

  useEffect(() => {
    const metricList = metrics.map((metric) => ({
      method: metric.metricName,
      desc: "Metric",
    }));

    const deviceList = devices.map((device) => ({
      method: device.name,
      desc: "Device",
    }));
    const countersList = counters.map((counter) => ({
      method: counter.name,
      desc: "Counter",
    }));

    console.log("metricList: ", metricList);
    addCompleter(myCompleter([...metricList, ...deviceList, ...countersList]));
  }, []);

  async function handleUpdateAction() {
    query.show();

    try {
      isThereErrorInCode(ifAction);
      isThereErrorInCode(thenAction);
      if (elseAction) {
        isThereErrorInCode(ifAction);
      }

      const newAction = {
        ...action,
        ifAction: ifAction.split("\n").join(";"),
        thenAction: thenAction.split("\n").join(";"),
        name: actionName,
        elseAction: !useElse
          ? null
          : elseAction.length
          ? elseAction.split("\n").join(";")
          : null,
      };

      await patchItem(newAction, newAction.actionId, "actionId", "action");
      query.hide();
      updateAction(newAction);
    } catch (e) {
      console.log(e.message);
      alert(`There was an error updating action:\n ${e.message}`);
      query.hide();
    }
  }

  function handleIfActionChange(value) {
    setIfAction(value);
  }
  function handleThenActionChange(value) {
    setThenAction(value);
  }
  function handleElseActionChange(value) {
    setElseAction(value);
  }

  function handleExampleChange({ target }, type) {
    const { value } = target;
    if (value !== "default") {
      if (type === "ELSE") {
        setElseAction(Editor_Examples[value]);
      } else if (type === "IF") {
        setIfAction(Editor_Examples[value]);
      } else if (type === "THEN") {
        setThenAction(Editor_Examples[value]);
      }
    }
  }

  return (
    <Collapse in>
      <NativeSelect
        style={{ marginTop: 20 }}
        onChange={(e) => handleExampleChange(e, "IF")}
      >
        <option value={"default"}>Examples</option>
        <option value={"last_metric_gt"}>
          Last value of metric greater than SLA
        </option>
      </NativeSelect>
      <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
        <Typography
          style={{
            fontWeight: "bold",
            width: "45px",
            background: mainYellow,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
          }}
        >
          IF
        </Typography>
        <AceEditor
          mode="javascript"
          theme={codeTheme}
          name="name"
          value={ifAction}
          style={{ borderLeft: `1px solid ${mainYellow}` }}
          height="100px"
          highlightActiveLine={false}
          width="100%"
          onChange={handleIfActionChange}
          name="ifAction"
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </div>
      <NativeSelect
        style={{ marginTop: 40 }}
        onChange={(e) => handleExampleChange(e, "THEN")}
      >
        <option value={"default"}>Examples</option>
        {ifThenOptions.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </NativeSelect>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            width: "45px",
            background: mainYellow,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
          }}
        >
          Then
        </Typography>
        <AceEditor
          mode="javascript"
          theme={codeTheme}
          name="name"
          value={thenAction}
          height="100px"
          onChange={handleThenActionChange}
          width="100%"
          style={{ borderLeft: `1px solid ${mainYellow}` }}
          highlightActiveLine={false}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </div>
      <div
        style={{
          marginTop: 20,
          marginBottom: 10,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox
          style={{ paddingLeft: 0 }}
          checked={useElse}
          onClick={() => setUseElse((state) => !state)}
          color="secondary"
        />
        <Typography>Use else statement</Typography>
      </div>
      {useElse && (
        <React.Fragment>
          <NativeSelect onChange={(e) => handleExampleChange(e, "ELSE")}>
            <option value={"default"}>Examples</option>
            {ifThenOptions.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </NativeSelect>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <Typography
              style={{
                fontWeight: "bold",
                width: "45px",
                background: "indianred",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
              }}
            >
              Else
            </Typography>
            {elseAction !== false && (
              <AceEditor
                mode="javascript"
                placeholder="An else statement is OPTIONAL."
                highlightActiveLine={false}
                onChange={handleElseActionChange}
                theme={codeTheme}
                name="name"
                value={elseAction}
                width="100%"
                height="100px"
                style={{ borderLeft: `1px solid indianred` }}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            )}
          </div>
        </React.Fragment>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleClose(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: 20 }}
          onClick={handleUpdateAction}
          disabled={!ifAction.trim() || !thenAction.trim()}
        >
          Save
        </Button>
      </div>
    </Collapse>
  );
}

export default EditAction;
