import React from "react";
import { useMediaQuery } from "@material-ui/core";

const PageWrapper = ({
  children,
  verticalCenter,
  horizontalCenter,
  fullHeight,
  margin,
  scrollable,
  style = {},
  id
}) => {
  const minWidthMenu = useMediaQuery("(min-width:800px)");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: horizontalCenter ? "center" : "inherit",
        justifyContent: verticalCenter ? "center" : "inherit",
        height: fullHeight ? "calc(100vh - 66px)" : "inherit",
        margin,
        overflow: scrollable ? "scroll" : "inherit",
        overflowX: "hidden",
        marginLeft: minWidthMenu ? 60 : 0,
        marginTop: 50,
        ...style
      }}
      id={id}
    >
      {children}
    </div>
  );
};

export default PageWrapper;
