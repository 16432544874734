import { v1 } from "uuid";
import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const counterUrl = `${apiEndpoint}/counter`;

export async function getCounters() {
  try {
    const response = await HttpService.get(counterUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createCounter(newCounter) {
  let counter = newCounter;
  if (!counter.counterId) {
    counter.counterId = v1();
  }

  try {
    const response = await HttpService.post(counterUrl, counter);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
