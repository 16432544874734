import { v1 } from "uuid";
import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const projectsUrl = `${apiEndpoint}/projects`;

export async function getProjects() {
  try {
    const response = await HttpService.get(projectsUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createProject(name) {
  try {
    const response = await HttpService.post(projectsUrl, {
      name,
      projectId: v1(),
    });
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
