import axios from "axios";
import {
  getCurrentCongitoUser,
  checkForTokenValidity,
  refreshSession,
} from "../CognitoService";

const axiosInstance = axios.create();

/**
 * Middleware that appends token to request
 */
axiosInstance.interceptors.request.use(async (config) => {
  let user = getCurrentCongitoUser();
  if (user) {
    const accessTokenValid = checkForTokenValidity(user);
    if (!accessTokenValid) {
      const refreshToken = user.refreshToken;
      user = await refreshSession(refreshToken);
    }

    const token = user.idToken.jwtToken;
    config.headers.Authorization = `${token}`;
  }
  // config.url = encodeParams(config.url);
  return config;
});

export default axiosInstance;
