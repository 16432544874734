import React from "react";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Button,
  useTheme,
  IconButton,
  CircularProgress,
  useMediaQuery
} from "@material-ui/core";
import { useContext } from "react";
import {
  DeviceContext,
  CounterContext,
  MetricsContext,
  QueryContext,
  UserContext,
  ThemeContext
} from "../../../../../../contextStore";
import { primaryDarkLight } from "../../../../../../styles/colors.js";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { distanceBetweenPoints, findAngle, getLastMetricData } from "./utils";
import { HighlightOff, Undo } from "@material-ui/icons";
import AddDeviceDialog from "../../../../../../components/AddDeviceDialog";
import { useLayoutEffect } from "react";
import { useWindowSize } from "../../../../../../utils/hooks";
import { getMaxColor } from "../../../../../../utils";

const useStyles = makeStyles(theme => ({
  container: {},
  cardContent: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  rowItem: {
    display: "flex",
    flexDirection: "row",
    marginRight: 10,
    alignItems: "center"
  },
  circle: {
    height: 20,
    width: 20,
    border: "0px solid white",
    borderRadius: 10,
    marginRight: 10
  },
  toolbarIcon: {
    cursor: "pointer",
    marginLeft: 10
  },
  workflowContainer: {
    margin: "20px 0px",
    alignSelf: "center",
    // width: "100%",
    position: "relative",
    border: `1px solid ${theme.palette.secondary.main}`
  },
  metricName: {
    textAlign: "center",
    whiteSpace: "pre"
  },
  img: {
    // height: "100%",
    // width: "100%",
    // objectPosition: "center",
    // overflow: "hidden",
    filter: "brightness(0.6)",
    // objectFit: "cover",
    // position: "absolute",
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    display: "block",
    maxWidth: "100%",
    maxHeight: 500
    // width: "auto",
    // height: "auto",
  }
}));

const laGarzaImg = require("../../../../../../assets/LaGarza.png");

const WorkflowWidget = ({ metricValues, metrics, user }) => {
  const [showAddDeviceDialog, setShowAddDeviceDialog] = useState(false);
  const containerRef = useRef(false);

  const classes = useStyles();

  const [devicesLocation, setDevicesLocation] = useState([]);

  const [
    currentContainerDimmensions,
    setCurrentContainerDimmensions
  ] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const minWidthForHistoricCharts = useMediaQuery("(min-width:1200px)");
  const minWidthForTitle = useMediaQuery("(min-width:900px)");
  const currentTheme = useContext(ThemeContext);

  function handleDeviceAdd(event) {
    const {
      offsetLeft,
      offsetTop,
      offsetHeight,
      offsetWidth
    } = containerRef.current;
    const { clientX, pageY } = event;

    const cx = clientX - offsetLeft;
    const cy = pageY - offsetTop;
    const newDevice = {
      cx,
      cy
    };

    const copy = devicesLocation.slice(0);
    copy.push(newDevice);
    setDevicesLocation(copy);
    setCurrentContainerDimmensions(`${offsetHeight},${offsetWidth}`);
  }

  function handleContainerClick(event) {
    handleDeviceAdd(event);
  }

  function getTopValue(cy, offHeight) {
    return Number(cy) * (containerRef.current.offsetHeight / offHeight) - 65;
  }

  function getLeftValue(cx, offHeight) {
    return Number(cx) * (containerRef.current.offsetHeight / offHeight) - 65;
  }

  function getSizeRatio(offHeight, size) {
    const { offsetHeight } = containerRef.current;
    return Number(size) * (offsetHeight / offHeight);
  }

  return (
    <React.Fragment>
      {showAddDeviceDialog && (
        <AddDeviceDialog
          handleClose={() => setShowAddDeviceDialog(false)}
          // handleAddDevice={handleAddDevice}
        />
      )}
      <Card
        style={{ minHeight: minWidthForHistoricCharts ? "100%" : "inherit" }}
        className={classes.container}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.row}>
            <Typography>Live Location Data</Typography>
          </div>
          {/* <Typography className={classes.instructions}>
            {instructions}
          </Typography> */}
          <div
            ref={containerRef}
            // onClick={edit ? handleContainerClick : () => {}}
            className={classes.workflowContainer}
          >
            <div style={{ position: "relative", width: "100%" }}>
              <img
                onLoad={() => {
                  setImageLoaded(true);
                }}
                className={classes.img}
                src={laGarzaImg}
              />
            </div>
            {!imageLoaded && (
              <CircularProgress
                size={60}
                color="secondary"
                style={{ position: "absolute", top: "45%", left: "45%" }}
              />
            )}
            {imageLoaded &&
              metrics.map((metric, idx) => {
                if (!metric.coordinates) return null;
                if (
                  user &&
                  user.customDash &&
                  user.customDash.length &&
                  !user.customDash.includes(metric.metricId)
                )
                  return null;
                const splitCoords = metric.coordinates.split(",");
                const value = getLastMetricData(metricValues, idx);
                return (
                  <div
                    key={metric.metricId}
                    style={{
                      position: "absolute",
                      top: getTopValue(splitCoords[1], splitCoords[2]),
                      left: getLeftValue(splitCoords[0], splitCoords[2]),
                      height: getSizeRatio(splitCoords[2], 130),
                      width: getSizeRatio(splitCoords[2], 130),
                      borderRadius: 65,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                      // border: `2px solid ${theme.palette.secondary.main}`,
                    }}
                  >
                    <Card
                      elevation={6}
                      style={{
                        border: "0px solid white",
                        borderRadius: 4,
                        padding: 8,
                        display: "flex",
                        backgroundColor:
                          currentTheme.theme === "light" && primaryDarkLight,
                        flexDirection: "column",
                        borderBottom: `2px solid #30f930`
                      }}
                    >
                      {minWidthForTitle && (
                        <Typography
                          style={{
                            fontSize: 14,
                            color: getMaxColor(value, metric.max)
                          }}
                          className={classes.metricName}
                        >
                          {metric.metricName}
                        </Typography>
                      )}
                      <Typography
                        style={{
                          alignSelf: "flex-end",
                          fontSize: 12,
                          color: "#30f930"
                        }}
                        className={classes.metricName}
                      >
                        {value} / {metric.max}
                      </Typography>
                    </Card>
                  </div>
                );
              })}
            {imageLoaded && (
              <div
                style={{
                  position: "absolute",
                  bottom: 10,
                  right: 20,
                  height: 50,
                  width: 100,
                  borderRadius: 65,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                  // border: `2px solid ${theme.palette.secondary.main}`,
                }}
              >
                <Card
                  elevation={6}
                  style={{
                    border: "0px solid white",
                    borderRadius: 4,
                    padding: 8,
                    width: "100%",
                    backgroundColor:
                      currentTheme.theme === "light" && primaryDarkLight,
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: `2px solid #30f930`
                  }}
                >
                  {minWidthForTitle && (
                    <Typography
                      style={{
                        fontSize: 14,
                        color: getMaxColor(50, 100)
                      }}
                      className={classes.metricName}
                    >
                      Total
                    </Typography>
                  )}
                  <Typography
                    style={{
                      alignSelf: "flex-end",
                      fontSize: 12,
                      color: "#30f930"
                    }}
                    className={classes.metricName}
                  >
                    {getLastMetricData(
                      metricValues,
                      metrics.findIndex(metric => metric.total)
                    )}{" "}
                    / {metrics.find(metric => metric.total).max}
                  </Typography>
                </Card>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

function deepCheck(prev, next) {
  return JSON.stringify(prev) !== JSON.stringify(next);
}

function areEqual(prevProps, nextProps) {
  const different =
    deepCheck(prevProps.metricValues, nextProps.metricValues) ||
    deepCheck(prevProps.user, nextProps.user) ||
    deepCheck(prevProps.metricValues, nextProps.metricValues);

  if (different) return false;
  return true;
}
export default React.memo(WorkflowWidget, areEqual);
