import { v1 } from "uuid";
import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const deviceUrl = `${apiEndpoint}/device`;

export async function getDevices() {
  try {
    const response = await HttpService.get(deviceUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createDevice(newDevice) {
  let device = { ...newDevice };
  if (!device.deviceId) {
    device.deviceId = v1();
  }
  try {
    const response = await HttpService.post(deviceUrl, device);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteDevice(deviceId) {
  try {
    await HttpService.delete(`${deviceUrl}?deviceId=${deviceId}`);
    return true;
  } catch (e) {
    throw e;
  }
}
