import { primaryDark } from "../../styles/colors";

export const defaultChartOptions = {
  chart: {
    background: primaryDark,
    toolbar: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
};
