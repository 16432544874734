import React from "react";
import { Typography, makeStyles, Slide, Divider } from "@material-ui/core";
import EmptyProject from "../EmptyProject";
import { useState } from "react";
import CreateNewProject from "../CreateNewProject";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  welcomeText: {
    textAlign: "center",
    marginTop: 40,
  },
  subText: {
    textAlign: "center",
  },
  divider: {
    marginTop: 20,
    background: theme.palette.secondary.main,
  },
}));

const VIEWS = { BASE: "BASE", CREATE: "CREATE" };

function NoProjectsView() {
  const classes = useStyles();
  const [currentView, setCurrentView] = useState(VIEWS.BASE);

  return (
    <div className={classes.container}>
      <Typography className={classes.welcomeText} variant="h5">
        Welcome to Cancerbero
      </Typography>
      <Typography className={classes.subText} variant="h6">
        Let's start by creating your first project.
      </Typography>
      <Divider className={classes.divider} />
      {currentView === VIEWS.BASE && (
        <EmptyProject
          handleNewProjectClick={() => setCurrentView(VIEWS.CREATE)}
        />
      )}
      {currentView === VIEWS.CREATE && <CreateNewProject />}
    </div>
  );
}

export default NoProjectsView;
