import { mainBlue } from "../styles/colors";

export function getZoomPlugin(tool) {
  return {
    pan: {
      enabled: tool === "PAN",
      mode: "xy",
      rangeMin: {
        x: null,
        y: null
      },
      rangeMax: {
        x: null,
        y: null
      },
      speed: 20,
      threshold: 10
    },
    zoom: {
      enabled: tool === "ZOOM",
      drag: true,
      mode: "xy",
      speed: 0.1,
      threshold: 2,
      sensitivity: 3
    }
  };
}

export function createLineAnnotation(value, date) {
  return {
    type: "line",
    drawTime: "afterDatasetsDraw",
    mode: "vertical",
    scaleID: "x-axis-0",

    // Data value to draw the line at
    value,
    borderColor: mainBlue,
    borderWidth: 2,
    borderDash: [2, 2],
    borderDashOffset: 5,
    label: {
      backgroundColor: "rgba(0,0,0,0.8)",
      fontFamily: "sans-serif",
      fontSize: 12,
      fontStyle: "bold",
      fontColor: "#fff",
      xPadding: 6,
      yPadding: 6,
      cornerRadius: 6,
      position: "center",
      xAdjust: 0,
      yAdjust: 0,
      enabled: false,
      content: date,
      rotation: 90
    }
  };
}

export function createBoxAnnotation(box, idx) {
  if (!box) return false;
  const color = "rgba(0, 0, 0, 0.6)";
  return {
    type: "box",
    drawTime: "beforeDatasetsDraw",
    xScaleID: "x-axis-0",
    yScaleID: "y-axis-0",
    xMin: box.x1,
    xMax: box.x2,
    yMin: box.y1,
    yMax: box.y2,
    borderColor: color,
    borderWidth: 1,
    backgroundColor: color
  };
}
