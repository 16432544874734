import React from "react";
import { IconButton, Switch, Typography } from "@material-ui/core";
import { RotateLeft, ZoomIn, PanTool } from "@material-ui/icons";

function ChartToolbar({ chart, tool, setTool }) {
  function resetZoom() {
    if (chart && chart.chartInstance) {
      chart.chartInstance.resetZoom();
    }
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={resetZoom} size="small">
        <RotateLeft style={{ fontSize: 16 }} />
      </IconButton>
      <IconButton
        color={tool === "ZOOM" ? "secondary" : "inherit"}
        size="small"
        onClick={() => setTool("ZOOM")}
      >
        <ZoomIn style={{ fontSize: 18 }} />
      </IconButton>
      <IconButton
        color={tool === "PAN" ? "secondary" : "inherit"}
        size="small"
        onClick={() => setTool("PAN")}
      >
        <PanTool style={{ fontSize: 14 }} />
      </IconButton>
    </div>
  );
}

export default ChartToolbar;
