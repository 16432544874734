import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";
import moment from "moment-timezone";

const metricUrl = `${apiEndpoint}/metric-value`;

export async function getMetricsValues(metricId, time, aggregate) {
  try {
    let url = `${metricUrl}?metricId=${metricId}`;
    if (time) {
      let since;
      let to;

      if (typeof time === "string" && time.includes("&")) {
        const split = time.split("&");
        since = split[0];
        to = split[1];
      } else {
        since = time;
      }
      url += `&since=${moment(since)
        .startOf("day")
        .format("x")}`;
      if (to) {
        url += `&to=${Number(
          moment(to)
            .endOf("day")
            .format("x")
        )}`;
      }
    }

    if (aggregate) {
      url += `&aggregate=true`;
    }

    if (!time) {
      url += "&minute=true";
    }

    url += `&timezone=${moment.tz.guess()}`;

    const response = await HttpService.get(url);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
