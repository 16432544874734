import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  makeStyles,
  DialogActions,
  Button
} from "@material-ui/core";
import { useFormInput } from "../../utils/hooks";
import { useContext } from "react";
import {
  ProjectContext,
  QueryContext,
  CounterContext
} from "../../contextStore";
import { createCounter } from "../../services/CounterService";
import { v1 } from "uuid";
import { createItem, patchItem } from "../../services/DbService";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    marginTop: 20,
    fontWeight: "bold",
    textAlign: "center"
  },
  counterTitle: {
    marginTop: 20
  },
  row: {
    margin: "10px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  deviceNameInput: {
    marginTop: 20,
    marginBottom: 10
  },
  deviceModelInput: {
    marginTop: 10,
    marginBottom: 10
  },
  leftAlignedInput: {
    marginRight: 20
  },
  createButton: {
    alignSelf: "flex-end",
    marginTop: 10
  },
  spinner: {
    margin: 40
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

function CounterDialog({ handleClose, handleAdd, counter }) {
  const actual = typeof counter === "boolean" ? false : counter;
  const classes = useStyles();
  const name = useFormInput(actual ? actual.name : "");
  const mnemonic = useFormInput(actual ? actual.mnemonic : "");

  const { currentProject } = useContext(ProjectContext);
  const query = useContext(QueryContext);

  function isDisabled() {
    if (!name.value.length || !mnemonic.value.length) {
      return true;
    }

    return false;
  }

  async function handleAddClick() {
    query.show();
    let newActual = {};

    if (actual) {
      newActual = { ...actual };
    }

    newActual = {
      ...newActual,
      name: name.value,
      mnemonic: mnemonic.value,
      projectId: currentProject.projectId
    };

    if (!actual) {
      newActual.counterId = v1().toUpperCase();
    }

    try {
      if (!actual) {
        const response = await createItem(newActual, "counter");
        handleAdd(response);
      } else {
        await patchItem(newActual, newActual.counterId, "counterId", "counter");
        handleAdd(newActual);
      }
      query.hide();
    } catch (e) {
      query.hide();
      alert("Error creating device");
    }
  }

  return (
    <Dialog fullWidth open>
      <DialogTitle>Device Configuration</DialogTitle>
      <DialogContent className={classes.content}>
        <React.Fragment>
          <TextField
            fullWidth
            {...mnemonic}
            label="Name"
            color="secondary"
            variant="filled"
            className={classes.deviceNameInput}
          />
          <TextField
            fullWidth
            {...name}
            label="Identifier"
            color="secondary"
            variant="filled"
            className={classes.deviceNameInput}
          />
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={handleAddClick}
          variant="contained"
          color="secondary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CounterDialog;
