import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const actionUrl = `${apiEndpoint}/action`;

export async function deleteAction(actionId) {
  try {
    await HttpService.delete(`${actionUrl}?actionId=${actionId}`);
    return true;
  } catch (e) {
    throw e;
  }
}

export async function getActions() {
  try {
    const response = await HttpService.get(actionUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createAction(newAction) {
  try {
    const response = await HttpService.post(actionUrl, newAction);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
