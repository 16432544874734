import React, { useContext } from "react";
import { ProjectContext, ThemeContext } from "../../contextStore";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  Card,
  CardContent,
  TableBody,
  IconButton
} from "@material-ui/core";
import { getTableRowStyle, getRealBusinessHours } from "../../utils";
import { Edit } from "@material-ui/icons";
import { useDialog } from "../../utils/hooks";
import ProjectDialog from "../ProjectDialog";

function ProjectList() {
  const { projects, updateProjects } = useContext(ProjectContext);
  const { theme } = useContext(ThemeContext);
  const projectDialog = useDialog(false);

  function handleAddProject(newProject) {
    updateProjects(newProject);
    projectDialog.close();
  }
  return (
    <React.Fragment>
      {projectDialog.open && (
        <ProjectDialog
          handleClose={() => projectDialog.close()}
          project={projectDialog.open}
          handleAdd={handleAddProject}
        />
      )}

      <Card style={{ margin: 20 }}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Operating Hours</TableCell>
                <TableCell padding="checkbox">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project, projectIdx) => (
                <TableRow
                  key={project.projectId}
                  style={getTableRowStyle(projectIdx, theme)}
                >
                  <TableCell>{project.name}</TableCell>
                  <TableCell>
                    {project.businessHours
                      ? getRealBusinessHours(project.businessHours)
                      : "-"}
                  </TableCell>
                  <TableCell padding="checkbox">
                    <IconButton
                      onClick={() => projectDialog.show(project)}
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default ProjectList;
