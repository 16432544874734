import moment from "moment-timezone";
import sort from "fast-sort";

export function isHiddenMetric(metric) {
  if (!metric) return true;
  if (metric.type === "AFLUENCIA") return true;
  return false;
}

const green = { light: "#00a106", dark: "#30f930" };

export function getMaxColor(val, max, theme = "dark") {
  return val / max < 75
    ? green[theme]
    : val / max < 90
    ? "#ff7a06"
    : green[theme];
}

export const Editor_Examples = {
  send_email:
    "cancerbero.sendEmail(String to, String sub, String body, String metricId)",
  last_metric_gt:
    "cancerbero.getLastMetricValue(ENTER_METRIC_NAME) > ENTER_VALUE",
  reset_all_devices:
    "cancerbero.resetAllDevices()\ncancerbero.setLastExecuted()"
};

export const CancerberoDefinition = `
  let hashple = {
    getLastMetricValue: () => {},
    getDeviceById: () => ({ output: () => {}}),
    getMetricMaxValue: () => {},
    getMetricById: () => {},
    sendEmail: () => {},
    getAllDevices: () => {},
    getLastExecuted: () => {},
    setLastExecuted: () => {},
    resetAllDevices: () => {},
    getCounterById: () => {},
    getAllCounters: () => {},
    getDeviceByName: () => {},
    getCounterByName: () => {},
    getMetricByName: () => {},
    getStalledDevicesNames: () => {},
    checkStalledDevices: () => {}
  }

  let cancerbero = hashple;
`;

const wordList = [
  {
    method: "hashple",
    desc: "variable"
  },
  {
    method: 'getCounterByName("COUNTER_NAME")',
    desc: "returns: (Counter)"
  },
  {
    method: 'getMetricByName("METRIC_NAME")',
    desc: "returns: (Metric)"
  },
  {
    method: 'getDeviceByName("DEVICE_NAME")',
    desc: "returns: (DeviceImpl)"
  },
  {
    method: "getDeviceById(String id)",
    desc: "returns: (DeviceImpl)"
  },
  {
    method: 'getLastMetricValue(cancerbero.getMetricByName("METRIC_NAME"))',
    desc: "return: (double)"
  },
  {
    method: "getMetricMaxValue(String id)",
    desc: "returns: (double)"
  },
  { method: "getMetricById(String id)", desc: "returns: (Metric)" },
  {
    method: "sendEmail(String to, String sub, String body, String metricId)",
    desc: "returns: (void)"
  },
  { method: "getAllDevices()", desc: "returns: (List<DeviceImpl>)" },
  { method: "getLastExecuted()", desc: "returns: (long)" },
  { method: "setLastExecuted()", desc: "returns: (void)" },
  { method: "resetAllDevices()", desc: "returns: (void)" },
  {
    method: "getCounterById(String id)",
    desc: "returns: (Counter)"
  },
  { method: "getAllCounters()", desc: "returns: (List<Counter>)" },
  { method: "checkStalledDevices(long timeout)", desc: "returns: (Boolean)" },
  { method: "getStalledDevicesNames(long timeout)", desc: "returns: (String)" }
];

export const ifThenOptions = [
  {
    label: "Send E-Mail",
    value: "send_email"
  },
  {
    label: "Reset all devices",
    value: "reset_all_devices"
  }
];

export const myCompleter = metrics => ({
  getCompletions: function(editor, session, pos, prefix, callback) {
    var completions = [];
    const fullWordList = [...wordList, ...metrics];

    fullWordList.forEach(function(w) {
      completions.push({
        value: w.method,
        meta: w.desc
      });
    });
    callback(null, completions);
  }
});

export function isThereErrorInCode(code) {
  const newCode = `${CancerberoDefinition}\n${code}`;
  try {
    new Function(newCode)();
    return false;
  } catch (e) {
    throw e;
  }
}

export function hexToRgb(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

function createTimeSlots(from, to) {
  const result = [];
  let startDate = moment(from).startOf("day");
  let hitTarget = false;

  const end = moment(to)
    .endOf("day")
    .startOf("hour")
    .format("x");

  while (!hitTarget) {
    if (startDate.format("x") === end) {
      result.push({
        label: moment(startDate).toString(),
        points: [
          Number(startDate.format("x")),
          Number(
            moment(startDate)
              .endOf("hour")
              .format("x")
          )
        ]
      });
      hitTarget = true;
      break;
    }

    result.push({
      label: moment(startDate).toString(),
      points: [
        Number(startDate.format("x")),
        Number(
          moment(startDate)
            .endOf("hour")
            .format("x")
        )
      ]
    });
    startDate.add("hour", 1);
  }

  return result;
}

export function aggregateData(data, from, to) {
  const timeSlots = createTimeSlots(from, to);
  const results = [];

  const aggregated = {};

  for (let j = 0; j < data.length; j++) {
    const metricData = data[j];
    const indexOfTime = timeSlots.findIndex(
      (item, idx) =>
        metricData.timestamp >= item.points[0] &&
        metricData.timestamp <= item.points[1]
    );
    if (indexOfTime < 0) continue;
    if (aggregated[timeSlots[indexOfTime].label]) {
      aggregated[timeSlots[indexOfTime].label].push(metricData.value);
    } else {
      aggregated[timeSlots[indexOfTime].label] = [metricData.value];
    }
  }

  const finalResult = {};

  const keys = Object.keys(aggregated);
  for (let k = 0; k < keys.length; k++) {
    const values = aggregated[keys[k]].map(item => item.live);

    let fullAggregation = 0;

    const max = Math.max(...values);

    const min = Math.min(...values);

    const sum = values.reduce((accum, val) => Number(accum) + Number(val));

    const average = Number(Number(sum / values.length).toFixed(2));
    const diff = Number(values[values.length - 1] - values[0]);
    const live = values[values.length - 1];

    fullAggregation = {
      max,
      min,
      sum,
      average,
      live,
      diff
    };

    finalResult[keys[k]] = fullAggregation;
  }

  const response = [];

  const entries = Object.entries(finalResult);

  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];
    response.push({
      value: value,
      timestamp: Number(moment(key).format("x"))
    });
  }

  return response;
}

export function getTableRowStyle(rowIndex, theme) {
  return {
    backgroundColor:
      rowIndex % 2
        ? "inherit"
        : theme === "light"
        ? "#f0f0f0"
        : "rgb(42, 42, 42)"
  };
}

export function getRealBusinessHours(businessHours) {
  const [start, end] = businessHours.split("-");
  const [hourStart, minuteStart] = start.split(":");
  const [hourEnd, minuteEnd] = end.split(":");

  const utcStart = moment()
    .utc()
    .set("hour", hourStart)
    .set("minute", minuteStart);
  const utcEnd = moment()
    .utc()
    .set("hour", hourEnd)
    .set("minute", minuteEnd);

  const startTime = moment(new Date(utcStart)).format("hh:mm a");
  const endTime = moment(new Date(utcEnd)).format("hh:mm a");

  return `${startTime} - ${endTime}`;
}

export function getRealTime(time, normal) {
  const [hourStart, minuteStart] = time.split(":");

  if (normal) {
    const utcStart = moment()
      .utc()
      .set("hour", hourStart)
      .set("minute", minuteStart)
      .set("month", moment(normal).get("month"))
      .set("date", moment(normal).get("date"));
    return moment(new Date(utcStart));
  }

  const utcStart = moment()
    .utc()
    .set("hour", hourStart)
    .set("minute", minuteStart);

  const startTime = moment(new Date(utcStart)).format("HH:mm");

  return startTime;
}

export function convertToBusinessHours(start, end) {
  const [hourStart, minuteStart] = start.split(":");
  const [hourEnd, minuteEnd] = end.split(":");

  const realStart = moment()
    .set("hour", hourStart)
    .set("minute", minuteStart)
    .utc()
    .format("HH:mm");
  const realEnd = moment()
    .set("hour", hourEnd)
    .set("minute", minuteEnd)
    .utc()
    .format("HH:mm");

  return `${realStart}-${realEnd}`;
}

export function isInBusinessHours(start, end, timestamp) {
  const rStart = getRealTime(start, timestamp);
  const rEnd = getRealTime(end, timestamp);

  const time = moment(timestamp);
  if (time.isSameOrAfter(rStart) && time.isSameOrBefore(rEnd)) {
    return true;
  }
  return false;
}

export function chunkArrayInGroups(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export function sortArray(arr, order, key) {
  return sort(arr)[order](u => u[key]);
}
