import React, { useMemo, useState, useContext } from "react";
import { Card, CardContent, Typography, NativeSelect } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import { Bar } from "react-chartjs-2";
import moment from "moment-timezone";

import { defaultChartOptions } from "../../services/ChartingService";
import { ThemeContext, ProjectContext } from "../../contextStore";
import { primaryDark } from "../../styles/colors";
import {
  hexToRgb,
  aggregateData,
  isInBusinessHours,
  chunkArrayInGroups
} from "../../utils";
import { useWindowSize } from "../../utils/hooks";
import ChartToolbar from "../ChartToolbar";
import { useRef } from "react";
import "chartjs-plugin-zoom";
import "chartjs-plugin-annotation";

import "hammerjs";
import { createLineAnnotation } from "../../utils/chartUtils";

const COLORS = [
  "#19d0e6",
  "#1068ef",
  "#6CDB9C",
  "#5dadec",
  "#bb35ca",
  "#FF6666",
  "#447825",
  "#6CDB9C",
  "#25da57",
  "#A64CA6"
];

const HistogramWidget = ({
  data,
  metric,
  index,
  time,
  onlyInBusinessHours
}) => {
  const currentTheme = useContext(ThemeContext);
  const [tool, setTool] = useState("ZOOM");
  const chartRef = useRef(false);
  const { currentProject } = useContext(ProjectContext);

  const { width } = useWindowSize();
  const series = useMemo(() => {
    if (!data) return { result: [], times: [] };
    let metricData = data;
    if (!time) {
      metricData = aggregateData(
        data,
        data[0].timestamp,
        data[data.length - 1].timestamp
      );
    }

    const { businessHours } = currentProject;

    let startTime;
    let endTime;

    if (businessHours) {
      [startTime, endTime] = businessHours.split("-");
    }

    const lastItem = metricData[metricData.length - 1].timestamp;
    const hour = moment(lastItem).get("hour");

    const timesLeft = Array(24 - hour).fill(1);
    const labelsAfter = [];
    const resultAfter = [];

    for (let i = 0; i < timesLeft.length; i++) {
      const label = moment(lastItem)
        .add("hour", i + 1)
        .toLocaleString();
      const inHours = isInBusinessHours(startTime, endTime, label);
      if (onlyInBusinessHours && !inHours) continue;

      labelsAfter.push(label);

      resultAfter.push(0);
    }

    let result = [];
    let times = [];

    for (let i = 0; i < metricData.length; i++) {
      const item = metricData[i];
      result.push(item.value.diff);
      times.push(moment(item.timestamp).toLocaleString());
    }
    result = [...result, ...resultAfter];
    times = [...times, ...labelsAfter];

    const realLabels = [];
    const realResult = [];

    for (let i = 0; i < times.length; i++) {
      const label = times[i];
      const inHours = isInBusinessHours(startTime, endTime, label);
      if (onlyInBusinessHours && !inHours) continue;
      realLabels.push(label);
      realResult.push(result[i]);
    }

    return { result: realResult, times: realLabels };
  }, [data, time, onlyInBusinessHours]);

  const fullData = {
    labels: series.times.slice(0),
    datasets: [
      {
        label: `${metric.metricName}-histogram`,
        backgroundColor: hexToRgb(COLORS[index], 0.3),
        borderColor: COLORS[index],
        borderWidth: 1,
        hoverBackgroundColor: hexToRgb(COLORS[index], 0.3),
        hoverBorderColor: COLORS[index],
        data: series.result.slice(0)
      }
    ]
  };

  const unit =
    !time ||
    moment()
      .subtract("day", 2)
      .isBefore(time)
      ? "hour"
      : "day";

  const annotations = useMemo(() => {
    const res = [];
    const { times } = series;

    if (!onlyInBusinessHours) {
      return res;
    }
    const splitLabels = chunkArrayInGroups(times, !time ? 24 * 60 : 9);

    if (splitLabels.length < 1) {
      return res;
    }

    for (let i = 0; i < splitLabels.length; i++) {
      const split = splitLabels[i];
      const end = split[split.length - 1];
      res.push(createLineAnnotation(end, moment(end).format("MM/DD")));
    }

    res.pop();
    return res;
  }, [data, onlyInBusinessHours]);

  return (
    <Card
      style={{
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <div />
            <ChartToolbar
              tool={tool}
              chart={chartRef.current}
              setTool={setTool}
            />
          </div>
          <Typography>
            Afluencia {metric.metricName.replace("Ocupación", "")}
          </Typography>
          <Bar
            data={fullData}
            ref={chartRef}
            options={{
              annotation: {
                annotations
              },
              plugins: {
                zoom: {
                  pan: {
                    enabled: tool === "PAN",
                    mode: "xy",
                    rangeMin: {
                      x: null,
                      y: null
                    },
                    rangeMax: {
                      x: null,
                      y: null
                    },
                    speed: 20,
                    threshold: 10
                  },
                  zoom: {
                    enabled: tool === "ZOOM",
                    drag: true,
                    mode: "xy",
                    speed: 0.1,
                    threshold: 2,
                    sensitivity: 3
                  }
                }
              },
              legend: {
                display: false,
                labels: {
                  fontColor: currentTheme.theme === "dark" ? "white" : "black",
                  boxWidth: 0,
                  fontSize: 16
                }
              },
              hover: {
                mode: "nearest",
                interset: true
              },
              tooltips: {
                mode: "index",
                intersect: false
              },
              scales: {
                xAxes: [
                  {
                    type: "time",
                    time: {
                      unit: unit
                    },
                    distribution: onlyInBusinessHours ? "series" : "linear",
                    ticks: {
                      maxRotation: 0,
                      autoSkipPadding: 10,
                      fontColor: currentTheme.theme === "dark" && "lightgray"
                    },
                    gridLines: {
                      zeroLineColor:
                        currentTheme.theme === "dark" && "lightgray"
                    }
                  }
                ],
                yAxes: [
                  {
                    ticks: {
                      fontColor: currentTheme.theme === "dark" && "lightgray"
                    },
                    gridLines: {
                      zeroLineColor:
                        currentTheme.theme === "dark" && "lightgray"
                    }
                  }
                ]
              },
              maintainAspectRatio: true
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

function deepCheck(prev, next) {
  return JSON.stringify(prev) !== JSON.stringify(next);
}

function areEqual(prevProps, nextProps) {
  const dataDifferent = deepCheck(prevProps.data, nextProps.data);
  const metricDifferent = deepCheck(prevProps.metric, nextProps.metric);
  const businessDiff = deepCheck(
    prevProps.onlyInBusinessHours,
    nextProps.onlyInBusinessHours
  );

  const different = dataDifferent || metricDifferent || businessDiff;

  if (different) return false;
  return true;
}

export default React.memo(HistogramWidget, areEqual);
