import React from "react";
import { LinearProgress } from "@material-ui/core";

function LinearBuffer() {
  return (
    <LinearProgress
      variant="query"
      color="secondary"
      style={{
        position: "fixed",
        top: 0,
        zIndex: 9999,
        height: 3,
        width: "100%",
      }}
    />
  );
}

export default LinearBuffer;
