import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  ButtonGroup,
  Button
} from "@material-ui/core";
import { isHiddenMetric } from "../../../../../../utils";
import { useContext } from "react";
import { MetricsContext, UserContext } from "../../../../../../contextStore";
import MetricDial from "../MetricDial";
import { useMemo } from "react";
import moment from "moment-timezone";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0 20px",
    backgroundColor: "#3E414F"
  },
  gridItem: {
    // margin: "20px 0px",
  },
  spinner: {
    margin: 40
  },
  dialCardContainer: {
    display: "flex",
    flexDirection: "column"
  },
  dialCard: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  radialDescription: {
    textAlign: "center"
  }
}));

const MetricDialContainer = ({
  metricValues,
  timeInterval,
  user,
  metrics,
  onlyInBusinessHours
}) => {
  const [mode, setMode] = useState("LIVE");

  function shouldShowMetric(metric) {
    if (!metric.length) return null;
    if (
      user.customDash &&
      user.customDash.length &&
      !user.customDash.includes(metric[0].metricId)
    )
      return false;

    const found = metrics.find(item => item.metricId === metric[0].metricId);
    if (!found || isHiddenMetric(found)) {
      return null;
    }
    return true;
  }

  const classes = useStyles();

  const isToday = useMemo(() => {
    return !timeInterval || moment().isSame(timeInterval, "day");
  }, [timeInterval]);

  useEffect(() => {
    if (!isToday) {
      setMode("AVERAGE");
    }
  }, [metricValues]);

  return (
    <Card className={classes.dialCardContainer}>
      <ButtonGroup
        style={{ alignSelf: "flex-end", marginRight: 20, marginTop: 20 }}
        color="secondary"
      >
        <Button
          onClick={() => setMode("LIVE")}
          disabled={!isToday}
          variant={mode === "LIVE" ? "contained" : "outlined"}
        >
          Live
        </Button>
        <Button
          onClick={() => setMode("AVERAGE")}
          variant={mode === "AVERAGE" ? "contained" : "outlined"}
        >
          Average
        </Button>
        <Button
          onClick={() => setMode("MAX")}
          variant={mode === "MAX" ? "contained" : "outlined"}
        >
          Max
        </Button>
      </ButtonGroup>
      <CardContent className={classes.dialCard}>
        {metricValues.map((metric, idx) => {
          if (!shouldShowMetric(metric)) return null;
          return (
            <div
              key={metric.metricId}
              style={{ margin: "20px -20px" }}
              className={classes.column}
            >
              <MetricDial
                metricData={metric}
                metric={metrics[idx].metricName}
                max={metrics[idx].max}
                index={idx}
                onlyInBusinessHours={onlyInBusinessHours}
                mode={mode}
              />
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
};
function deepCheck(prev, next) {
  return JSON.stringify(prev) !== JSON.stringify(next);
}

function areEqual(prevProps, nextProps) {
  const different =
    deepCheck(prevProps.metricValues, nextProps.metricValues) ||
    deepCheck(prevProps.user, nextProps.user) ||
    deepCheck(prevProps.timeInterval, nextProps.timeInterval) ||
    deepCheck(prevProps.metricValues, nextProps.metricValues) ||
    deepCheck(prevProps.onlyInBusinessHours, nextProps.onlyInBusinessHours);

  if (different) return false;
  return true;
}

export default React.memo(MetricDialContainer, areEqual);
