import React, { useState, useCallback } from "react";
import { Typography, Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import WorkflowWidget from "./components/WorkflowWidget";
import { useContext } from "react";
import {
  MetricsContext,
  QueryContext,
  UserContext
} from "../../../../contextStore";
import { useEffect } from "react";
import { getMetricsValues } from "../../../../services/MetricValueService";
import HistoricMetricWidget from "./components/HistoricMetricWidget";
import { useRef } from "react";
import DashToolbar from "./components/DashToolbar";
import { isHiddenMetric } from "../../../../utils";
import HistogramWidget from "../../../../components/HistogramWidget";
import MetricDialContainer from "./components/MetricDialContainer";
import ForecastContainer from "../../../../components/ForecastContainer";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0 20px"
  },
  gridItem: {
    // margin: "20px 0px",
  },
  spinner: {
    margin: 40
  },
  dialCard: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  radialDescription: {
    textAlign: "center"
  }
}));

const Dashboard = React.memo(() => {
  const classes = useStyles();
  const { metrics } = useContext(MetricsContext);
  const [metricValues, setMetricValues] = useState([]);
  const minWidthForHistoricCharts = useMediaQuery("(min-width:1200px)");
  const interval = useRef(false);
  const timeIntervalRef = useRef(false);
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);
  const query = useContext(QueryContext);
  const [onlyInBusinessHours, setOnlyInBusinessHours] = useState(true);
  const [analyze, setAnalyze] = useState(false);

  const loadData = useCallback(async () => {
    const promises = [];

    query.show();
    for (let i = 0; i < metrics.length; i++) {
      const metric = metrics[i];
      promises.push(
        getMetricsValues(metric.metricId, timeIntervalRef.current, true)
      );
    }

    try {
      const values = await Promise.all(promises);
      setMetricValues(values);
      if (loading) {
        setLoading(false);
      }
      query.hide();
      if (!interval.current) {
        interval.current = setInterval(() => {
          loadData();
        }, 10000);
      }
    } catch (e) {
      setLoading(false);
      query.hide();

      console.log("e: ", e);
    }
  }, []);

  useEffect(() => {
    loadData();
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = false;
      }
    };
  }, []);

  function handleAnalyzeClick(metricId) {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = false;
    }

    const metric = metrics.find(item => item.metricId === metricId);

    setAnalyze(metric);
  }

  function shouldShowMetric(metric) {
    if (!metric.length) return null;
    if (
      user.customDash &&
      user.customDash.length &&
      !user.customDash.includes(metric[0].metricId)
    )
      return false;
    const found = metrics.find(item => item.metricId === metric[0].metricId);
    if (!found || isHiddenMetric(found)) {
      return null;
    }
    return true;
  }

  return (
    <React.Fragment>
      {loading && (
        <React.Fragment>
          <Typography style={{ marginTop: 40 }} variant="h6"></Typography>
        </React.Fragment>
      )}
      {!loading && metricValues.length && !analyze && (
        <Grid
          id="Home_Page"
          wrap="wrap"
          spacing={2}
          className={classes.container}
          container
        >
          <Grid item style={{ marginTop: 20 }} xs={12}>
            <DashToolbar
              timeIntervalRef={timeIntervalRef}
              loadData={loadData}
              onlyInBusinessHours={onlyInBusinessHours}
              setOnlyInBusinessHours={setOnlyInBusinessHours}
            />
            {timeIntervalRef.current &&
              typeof timeIntervalRef.current === "string" &&
              timeIntervalRef.current.includes("&") && (
                <Typography style={{ marginTop: 20 }}>
                  From {timeIntervalRef.current.split("&")[0]} To{"     "}
                  {timeIntervalRef.current.split("&")[1]}
                </Typography>
              )}
          </Grid>

          <Grid
            className={classes.gridItem}
            xs={minWidthForHistoricCharts ? 6 : 12}
            item
          >
            <WorkflowWidget
              user={user}
              metrics={metrics}
              metricValues={metricValues}
            />
          </Grid>

          <Grid
            className={classes.gridItem}
            xs={minWidthForHistoricCharts ? 6 : 12}
            item
          >
            <MetricDialContainer
              timeInterval={timeIntervalRef.current}
              metricValues={metricValues}
              user={user}
              metrics={metrics}
              onlyInBusinessHours={onlyInBusinessHours}
            />
          </Grid>

          {metricValues.map((metric, idx) => {
            if (!shouldShowMetric(metric)) return null;
            return (
              <Grid className={classes.gridItem} md={6} xs={12} item>
                <HistoricMetricWidget
                  metricData={metric}
                  index={idx}
                  onlyInBusinessHours={onlyInBusinessHours}
                  time={timeIntervalRef.current}
                  onAnalyzeClick={() => handleAnalyzeClick(metric[0].metricId)}
                  metric={metrics[idx].metricName}
                />
                <HistogramWidget
                  data={metric}
                  metric={metrics[idx]}
                  index={idx}
                  onlyInBusinessHours={onlyInBusinessHours}
                  time={timeIntervalRef.current}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {!loading && analyze && (
        <ForecastContainer
          metric={analyze}
          handleBack={() => setAnalyze(false)}
        />
      )}
    </React.Fragment>
  );
});

export default Dashboard;
