import React, { useContext } from "react";
import {
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Tooltip,
  ButtonGroup,
  Button,
  Switch,
  Typography
} from "@material-ui/core";
import {
  Save,
  Assessment,
  Widgets,
  Watch,
  WatchLater,
  WatchLaterOutlined
} from "@material-ui/icons";
import { useState } from "react";
import SaveToPdfDialog from "../../../../../../components/SaveToPdfDialog";
import moment from "moment-timezone";
import { useMemo } from "react";
import DateRangeSelectorDialog from "../../../../../../components/DateRangeSelectorDialog";
import CustomDashboardDialog from "../../../../../../components/CustomDashboardDialog";
import { QueryContext } from "../../../../../../contextStore";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 0,
    justifyContent: "space-between"
  },
  icon: {
    margin: "0px 5px",
    // border: "1px solid white",
    "&:hover": {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main
    }
  }
}));

function DashToolbar({
  timeIntervalRef,
  loadData,
  onlyInBusinessHours,
  setOnlyInBusinessHours
}) {
  const classes = useStyles();
  const [showSaveToPdfDialog, setShowSaveToPdfDialog] = useState(false);
  const [showCustomDialog, setShowCustomDialog] = useState(false);
  const [showCustomDashboardDialog, setShowCustomDashboardDialog] = useState(
    false
  );

  const query = useContext(QueryContext);

  function handleYesterdayClick() {
    const time = moment()
      .subtract("day", 1)
      .startOf("day");

    timeIntervalRef.current = time;

    loadData();
  }

  function handleTodayClick() {
    const time = false;
    timeIntervalRef.current = time;
    loadData();
  }

  const isToday = useMemo(() => {
    return (
      !timeIntervalRef.current ||
      moment().isSame(timeIntervalRef.current, "day")
    );
  }, [timeIntervalRef, query.active]);

  const isYesterday = useMemo(() => {
    return moment()
      .subtract("day", 1)
      .startOf("day")
      .isSame(timeIntervalRef.current, "day");
  }, [timeIntervalRef, query.active]);

  const is7Days = useMemo(() => {
    return moment()
      .subtract("day", 7)
      .startOf("day")
      .isSame(timeIntervalRef.current, "day");
  }, [timeIntervalRef, query.active]);

  function handle7DaysClick() {
    const time = moment()
      .subtract("day", 7)
      .startOf("day");
    timeIntervalRef.current = time;
    loadData();
  }

  function handleCustomClick() {
    setShowCustomDialog(true);
  }

  function handleCustomSubmit(from, to) {
    setShowCustomDialog(false);
    timeIntervalRef.current = `${from}&${to}`;
    loadData();
  }

  return (
    <React.Fragment>
      {showSaveToPdfDialog && (
        <SaveToPdfDialog handleClose={() => setShowSaveToPdfDialog(false)} />
      )}
      {showCustomDashboardDialog && (
        <CustomDashboardDialog
          onClose={() => setShowCustomDashboardDialog(false)}
        />
      )}
      {showCustomDialog && (
        <DateRangeSelectorDialog
          onClose={() => setShowCustomDialog(false)}
          onSubmit={handleCustomSubmit}
        />
      )}
      <div className={classes.container}>
        <ButtonGroup color="secondary">
          <Button
            onClick={handleTodayClick}
            size="small"
            variant={isToday ? "contained" : "outlined"}
          >
            Today
          </Button>
          <Button
            onClick={handleYesterdayClick}
            variant={isYesterday ? "contained" : "outlined"}
            size="small"
          >
            Yesterday
          </Button>
          <Button
            onClick={handle7DaysClick}
            variant={is7Days ? "contained" : "outlined"}
            size="small"
          >
            Week
          </Button>
          <Button
            onClick={handleCustomClick}
            variant={
              !is7Days && !isToday && !isYesterday ? "contained" : "outlined"
            }
            size="small"
          >
            Custom
          </Button>
        </ButtonGroup>
        <div>
          <Tooltip title="Show Business Hours">
            <IconButton
              size="small"
              onClick={() => setOnlyInBusinessHours(state => !state)}
              color="secondary"
            >
              {onlyInBusinessHours ? (
                <WatchLater />
              ) : (
                <WatchLaterOutlined style={{ color: "white" }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Configure Dashboard">
            <IconButton
              size="small"
              onClick={() => setShowCustomDashboardDialog(true)}
              className={classes.icon}
            >
              <Widgets />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DashToolbar;
