import React, { useContext } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useFormInput } from "../../utils/hooks";
import { QueryContext, UserContext } from "../../contextStore";
import { signUpUser } from "../../services/CognitoService";
import { createCustomer } from "../../services/CustomerService";

function CreateSubAccountDialog({ onClose, onSubmit }) {
  const email = useFormInput("");
  const name = useFormInput("");
  const password = useFormInput("");
  const query = useContext(QueryContext);
  const user = useContext(UserContext);

  async function onCreateClick() {
    query.show();
    try {
      const userResp = await signUpUser(email.value, password.value);
      const { userSub } = userResp;
      await createCustomer({
        email: email.value,
        sub: userSub,
        name: name.value,
        parent: user.customerId,
      });
      onSubmit();
    } catch (e) {
      alert("Error creating sub account");
      query.hide();
    }
  }
  return (
    <Dialog open fullWidth>
      <DialogTitle>Create Sub Account</DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          fullWidth
          {...name}
          variant="filled"
          color="secondary"
          style={{ marginTop: 20 }}
          label="Name"
        />
        <TextField
          {...email}
          variant="filled"
          color="secondary"
          label="Email"
          style={{ marginTop: 20 }}
          fullWidth
        />
        <TextField
          {...password}
          variant="filled"
          color="secondary"
          label="Password"
          style={{ marginTop: 20 }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={query.active}
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={
            query.active || !name.value || !password.value || !email.value
          }
          onClick={onCreateClick}
          variant="contained"
          color="secondary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateSubAccountDialog;
