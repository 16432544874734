import { v1 } from "uuid";
import HttpService from "../HttpService";
import { apiEndpoint } from "../../config";

const metricUrl = `${apiEndpoint}/metric`;

export async function getMetrics() {
  try {
    const response = await HttpService.get(metricUrl);
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createMetric({ metricName, expression }) {
  try {
    const response = await HttpService.post(metricUrl, {
      metricName,
      metricId: v1(),
      expression,
    });
    const { data } = response;
    return data;
  } catch (e) {
    throw e;
  }
}
