import React, { useState } from "react";
import {
  List,
  Drawer,
  ListItem,
  IconButton,
  ListItemIcon,
  makeStyles,
  Tooltip,
  useTheme,
  useMediaQuery,
  SwipeableDrawer
} from "@material-ui/core";
import {
  Dashboard,
  Settings,
  Notifications,
  PersonPinCircle,
  Person,
  Assessment,
  NightsStay,
  WbSunny,
  ExitToApp
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { ProjectContext, ThemeContext } from "../../contextStore";
import { signOut } from "../../services/CognitoService";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: props => (props.minWidthMenu ? 50 : 0),
    height: props => (props.minWidthMenu ? "calc(100% - 50px)" : "100%"),
    zIndex: 1
  },
  listItemIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const options = [
  {
    tooltip: "Dashboard",
    path: "/",
    icon: () => <Dashboard />
  },
  {
    tooltip: "Reporting",
    path: "/reports",
    icon: () => <Assessment />
  },
  {
    tooltip: "Settings",
    path: "/settings",
    icon: () => <Settings />
  }
];

const logo = require("../../assets/logo.png");

function SideBar({ menuOpen, setMenuOpen }) {
  const minWidthMenu = useMediaQuery("(min-width:800px)");
  const classes = useStyles({ minWidthMenu });
  const theme = useTheme();
  const history = useHistory();
  const { theme: currentTheme, changeTheme } = useContext(ThemeContext);

  const { projects } = useContext(ProjectContext);

  function isActive(path) {
    const { pathname } = window.location;
    if (pathname === path) return true;
    return false;
  }

  function handleIconClick(path) {
    history.push(path);
  }

  function handleLogOut() {
    signOut();
    window.location.href = "/";
  }

  return (
    <SwipeableDrawer
      open={minWidthMenu ? true : menuOpen}
      PaperProps={{ className: classes.container }}
      variant={minWidthMenu ? "permanent" : "temporary"}
      onClose={() => setMenuOpen(false)}
    >
      <List>
        {!minWidthMenu && (
          <ListItem divider disableGutters>
            <img
              style={{ paddingLeft: 15 }}
              src={require("../../assets/hashple_logo.png")}
              height={20}
            />
          </ListItem>
        )}
        {options.map((option, idx) => (
          <ListItem
            divider={idx === options.length - 1}
            key={option.tooltip}
            disableGutters
          >
            <Tooltip enterDelay={1000} title={option.tooltip}>
              <ListItemIcon className={classes.listItemIcon}>
                <IconButton
                  onClick={() => handleIconClick(option.path)}
                  disabled={!projects || !projects.length}
                  style={{
                    color:
                      isActive(option.path) && projects && projects.length
                        ? theme.palette.secondary.main
                        : "lightgray"
                  }}
                >
                  {option.icon()}
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        ))}
        <ListItem disableGutters>
          <ListItemIcon className={classes.listItemIcon}>
            <IconButton
              onClick={handleLogOut}
              style={{
                color: "lightgray"
              }}
            >
              <ExitToApp />
            </IconButton>
          </ListItemIcon>
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
}

export default SideBar;
