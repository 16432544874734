import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  makeStyles,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useFormInput } from "../../utils/hooks";
import { useState } from "react";
import { createDevice } from "../../services/DeviceService";
import { useContext } from "react";
import { ProjectContext } from "../../contextStore";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginTop: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  counterTitle: {
    marginTop: 20,
  },
  row: {
    margin: "10px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  deviceNameInput: {
    marginTop: 20,
    marginBottom: 10,
  },
  deviceModelInput: {
    marginTop: 10,
    marginBottom: 10,
  },
  leftAlignedInput: {
    marginRight: 20,
  },
  createButton: {
    alignSelf: "flex-end",
    marginTop: 10,
  },
  spinner: {
    margin: 40,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function AddDeviceDialog({ handleClose, handleAddDevice }) {
  const classes = useStyles();
  const deviceName = useFormInput("");
  const deviceModel = useFormInput("");
  const ip = useFormInput("");
  const port = useFormInput("");
  const username = useFormInput("");
  const password = useFormInput("");
  const { currentProject } = useContext(ProjectContext);

  const [loading, setLoading] = useState(false);

  function isDisabled() {
    if (
      !deviceName.value.length ||
      !deviceModel.value.length ||
      !ip.value.length ||
      !port.value.length ||
      !username.value.length ||
      !password.value.length
    ) {
      return true;
    }

    return false;
  }

  async function handleAddDeviceClick() {
    setLoading(true);
    const newDevice = {
      name: deviceName.value,
      model: deviceModel.value,
      ipPort: `${ip.value}:${port.value}`,
      username: username.value,
      password: password.value,
      projectId: currentProject.projectId,
    };

    try {
      const deviceResponse = await createDevice(newDevice);
      handleAddDevice(deviceResponse);
    } catch (e) {
      setLoading(false);
      alert("Error creating device");
    }
  }

  return (
    <Dialog fullWidth open>
      <DialogTitle>Add New Device</DialogTitle>
      <DialogContent className={classes.content}>
        <React.Fragment>
          {loading && (
            <React.Fragment>
              <CircularProgress
                className={classes.spinner}
                size={60}
                color="secondary"
              />
              <Typography>Creating Device...</Typography>
            </React.Fragment>
          )}
          {!loading && (
            <React.Fragment>
              <TextField
                fullWidth
                {...deviceName}
                label="Device Name"
                color="secondary"
                variant="filled"
                className={classes.deviceNameInput}
              />
              <div className={classes.row}>
                <TextField
                  className={classes.leftAlignedInput}
                  fullWidth
                  {...ip}
                  label="IP Address"
                  variant="filled"
                  color="secondary"
                />
                <TextField
                  color="secondary"
                  fullWidth
                  {...port}
                  label="Port"
                  variant="filled"
                />
              </div>
              <div className={classes.row}>
                <TextField
                  className={classes.leftAlignedInput}
                  fullWidth
                  {...username}
                  label="Username"
                  variant="filled"
                  color="secondary"
                />
                <TextField
                  color="secondary"
                  fullWidth
                  {...password}
                  label="Password"
                  variant="filled"
                />
              </div>
              <TextField
                fullWidth
                {...deviceModel}
                label="Device Model"
                variant="filled"
                color="secondary"
                className={classes.deviceModelInput}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          disabled={isDisabled() || loading}
          onClick={handleAddDeviceClick}
          variant="contained"
          color="secondary"
        >
          Add Device
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddDeviceDialog;
