import React, { useContext } from "react";
import PageWrapper from "../../../PageWrapper";
import {
  Typography,
  makeStyles,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useFormInput } from "../../../../utils/hooks";
import { createDevice } from "../../../../services/DeviceService";
import { useState } from "react";
import {
  DeviceContext,
  CounterContext,
  ProjectContext,
  MetricsContext,
} from "../../../../contextStore";
import { createCounter } from "../../../../services/CounterService";
import { createMetric } from "../../../../services/MetricService";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginTop: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  counterTitle: {
    marginTop: 20,
  },
  row: {
    margin: "10px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  deviceNameInput: {
    marginTop: 20,
    marginBottom: 10,
  },
  deviceModelInput: {
    marginTop: 10,
    marginBottom: 10,
  },
  leftAlignedInput: {
    marginRight: 20,
  },
  createButton: {
    alignSelf: "flex-end",
    marginTop: 10,
  },
  spinner: {
    margin: 40,
  },
}));

function CreateFirstDevice() {
  const classes = useStyles();
  const deviceName = useFormInput("");
  const deviceModel = useFormInput("");
  const ip = useFormInput("");
  const port = useFormInput("");
  const username = useFormInput("");
  const password = useFormInput("");

  const [loading, setLoading] = useState(false);
  const { updateDevices } = useContext(DeviceContext);
  const { updateCounters } = useContext(CounterContext);
  const { currentProject } = useContext(ProjectContext);
  const { updateMetrics } = useContext(MetricsContext);
  const [currentView, setCurrentView] = useState("DEVICE");

  const counterNameIn = useFormInput("");
  const counterIdentifierIn = useFormInput("");

  const counterNameOut = useFormInput("");
  const counterIdentifierOut = useFormInput("");

  function isDisabled() {
    if (
      !deviceName.value.length ||
      !deviceModel.value.length ||
      !ip.value.length ||
      !port.value.length ||
      !username.value.length ||
      !password.value.length
    ) {
      return true;
    }

    return false;
  }

  function onContinueClick() {
    setCurrentView("COUNTER");
  }

  async function onCreateClick() {
    setLoading(true);
    const newDevice = {
      name: deviceName.value,
      model: deviceModel.value,
      ipPort: `${ip.value}:${port.value}`,
      username: username.value,
      password: password.value,
      projectId: currentProject.projectId,
    };

    try {
      const deviceResponse = await createDevice(newDevice);

      const newCounters = [
        {
          name: counterNameIn.value,
          mnemonic: counterIdentifierIn.value,
          deviceId: deviceResponse.deviceId,
          projectId: currentProject.projectId,
        },
        {
          name: counterNameOut.value,
          mnemonic: counterIdentifierOut.value,
          deviceId: deviceResponse.deviceId,
          projectId: currentProject.projectId,
        },
      ];

      const promises = [
        createCounter(newCounters[0]),
        createCounter(newCounters[1]),
      ];
      const counterResponse = await Promise.all(promises);

      const newMetric = {
        expression: `$${newCounters[0].mnemonic} - $${newCounters[1].mnemonic}`,
        metricName: "Example Metric",
      };

      const metricResponse = await createMetric(newMetric);

      updateDevices(deviceResponse);
      updateCounters(counterResponse);
      updateMetrics(metricResponse);
    } catch (e) {
      setLoading(false);
      alert("Error creating device");
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <React.Fragment>
          <CircularProgress
            className={classes.spinner}
            size={100}
            color="secondary"
          />
          <Typography variant="h6">Creating WorkFlow</Typography>
        </React.Fragment>
      )}
      {!loading && currentView === "DEVICE" && (
        <div className={classes.container}>
          <Typography className={classes.title} variant="h6">
            Let's create the first device in your workflow
          </Typography>
          <TextField
            fullWidth
            {...deviceName}
            label="Device Name"
            color="secondary"
            variant="filled"
            className={classes.deviceNameInput}
          />
          <div className={classes.row}>
            <TextField
              className={classes.leftAlignedInput}
              fullWidth
              {...ip}
              label="IP Address"
              variant="filled"
              color="secondary"
            />
            <TextField
              color="secondary"
              fullWidth
              {...port}
              label="Port"
              variant="filled"
            />
          </div>
          <div className={classes.row}>
            <TextField
              className={classes.leftAlignedInput}
              fullWidth
              {...username}
              label="Username"
              variant="filled"
              color="secondary"
            />
            <TextField
              color="secondary"
              fullWidth
              {...password}
              label="Password"
              variant="filled"
            />
          </div>
          <TextField
            fullWidth
            {...deviceModel}
            label="Device Model"
            variant="filled"
            color="secondary"
            className={classes.deviceModelInput}
          />
          <Button
            className={classes.createButton}
            variant="contained"
            color="secondary"
            onClick={onContinueClick}
            disabled={isDisabled()}
          >
            Next: Counters
          </Button>
        </div>
      )}
      {!loading && currentView === "COUNTER" && (
        <div className={classes.container}>
          <Typography className={classes.title} variant="h6">
            Let's attach a pair of counters to that device
          </Typography>
          <Typography className={classes.counterTitle} variant="h6">
            Counter #1
          </Typography>
          <div className={classes.row}>
            <TextField
              className={classes.leftAlignedInput}
              fullWidth
              {...counterNameIn}
              label="Counter Name"
              variant="filled"
              color="secondary"
            />
            <TextField
              color="secondary"
              fullWidth
              {...counterIdentifierIn}
              label="Counter Identifier"
              variant="filled"
            />
          </div>
          <Typography className={classes.counterTitle} variant="h6">
            Counter #2
          </Typography>
          <div className={classes.row}>
            <TextField
              className={classes.leftAlignedInput}
              fullWidth
              {...counterNameOut}
              label="Counter Name"
              variant="filled"
              color="secondary"
            />
            <TextField
              color="secondary"
              fullWidth
              {...counterIdentifierOut}
              label="Counter Identifier"
              variant="filled"
            />
          </div>
          <Button
            className={classes.createButton}
            variant="contained"
            color="secondary"
            onClick={onCreateClick}
            disabled={
              isDisabled() ||
              !counterIdentifierIn.value.length ||
              !counterNameIn.value.length ||
              !counterIdentifierOut.value.length ||
              !counterNameOut.value.length
            }
          >
            Create Device & Counter
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}

export default CreateFirstDevice;
