import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useContext } from "react";
import { UserContext, MetricsContext, QueryContext } from "../../contextStore";
import { useState } from "react";
import { patchUser } from "../../services/CustomerService";

function CustomDashboardDialog({ onClose }) {
  const user = useContext(UserContext);
  const { metrics } = useContext(MetricsContext);
  const [customDash, setCustomDash] = useState(user.customDash || []);
  const query = useContext(QueryContext);

  function onCheckboxClick(metricId) {
    if (customDash.includes(metricId)) {
      const newDash = [...customDash];
      const idx = customDash.indexOf(metricId);
      newDash.splice(idx, 1);
      setCustomDash(newDash);
    } else {
      const newDash = [...customDash, metricId];
      setCustomDash(newDash);
    }
  }

  async function onSaveClick() {
    query.show();
    const newUser = { ...user, customDash };
    try {
      await patchUser({ customDash });
      user.updateUser(newUser);
      query.hide();
      onClose();
    } catch (e) {
      console.log("Error: ", e);
      query.hide();

      alert("Error updating custom dashboard");
    }
  }

  return (
    <Dialog fullWidth open>
      <DialogTitle>Customize your dashboard</DialogTitle>
      <DialogContent>
        <Typography>Which metrics would you like to see?</Typography>
        <Typography>(If none are selected, all are shown)</Typography>

        <List>
          {metrics.map((metric) => (
            <ListItem key={metric.metricId} divider>
              <Checkbox
                onClick={() => onCheckboxClick(metric.metricId)}
                checked={customDash.includes(metric.metricId)}
              />
              <ListItemText primary={metric.metricName} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          disabled={query.active}
          onClick={onSaveClick}
          variant="contained"
          color="secondary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDashboardDialog;
